import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { URLS } from "../../../../../environments/environment";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class StoreService {

  constructor(private http: HttpClient) {
  }

  createNewStore(description: string, city: string, streetNumber: string,
                 street: string, country: string,
                 fullAddress: string,lat:number,lng:number,district1:string,district2:string) {
    let params: any = {
      city, country,
      fullAddress,lat,lng
    };
    if (streetNumber) {
      params = { ...params, streetNumber };
    }if (district1) {
      params = { ...params, district1 };
    }if (district2) {
      params = { ...params, district2 };
    }
    if (street) {
      params = { ...params, street };
    } if (description) {
      params = { ...params, description };
    }
    return this.http.post(`${URLS.API}store/create-new-store`, {
      ...params
    }).pipe(map((res: any) => {
      return res.data;
    }));
  }

  removeStore(store:string) {
    return this.http.post(`${URLS.API}store/remove-store`, {
     store
    }).pipe(map((res: any) => {
      return res.data;
    }));
  }

  getStores() {
    return this.http.get(`${URLS.API}store/get-business-profile-stores`).pipe(map((res: any) => {
      return res.data;
    }));
  }
}
