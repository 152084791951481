import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { loadTagPostsData, upsertPostsByTag } from "../tag/tag.actions";
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import {
  upsertPost,
  upsertPostByTagSucsess, upsertPostFailed, upsertPostsByGroupSucsess,
  upsertPostsSucsess,
  upsertPostSucsess, upsertSearchResultPosts, upsertSearchResultPostsSucsess
} from "./post.actions";
import { TagService } from "../../pages/tag/tag.service";
import { PostService } from "../../pages/post/post.service";
import { upsertPostCommentsSuccess } from "../post-comment/post-comment.actions";
import { UserProfileService } from "../../pages/salez-profile/user-profile.service";
import { loadUserPostsData, upsertPostsByUser } from "../user-profile/user-profile.actions";
import { GlobalSearchService } from "../../pages/global-search/global-search.service";
import { loadGroupPostsData, upsertPostsByGroup } from "../group/group.actions";
import { GroupService } from "../../pages/group/group.service";

export function getComments(posts: any[]) {
  let comments: any = [];
  posts.map((post: any) => {
    if(post?.socialPostData?.comments.length)
    {
      comments = [...comments, ...post?.socialPostData?.comments];
    }
  });
  return comments;
}

@Injectable()
export class PostEffects {


  loadTagPosts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(upsertPostsByTag),
      switchMap((action) =>
        this.tagService.getTagPosts(action.slug, action.page, action.limit, action.postType).pipe(
          mergeMap((res: any) => {
            //console.log(res)
            const comments = getComments(res.posts);
            return [upsertPostByTagSucsess({ posts: res.posts }),
              loadTagPostsData({
                totalPosts: res.totalPosts, currentPostPage: res.currentPage, tagSlug: action.slug,
                postType: action.postType
              }),
              upsertPostCommentsSuccess({ postComments: comments })];
          }),
          catchError(e => {
            return [];
          })
        )
      )
    );
  });

  loadGroupPosts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(upsertPostsByGroup),
      switchMap((action) =>
        this.groupService.getGroupPosts(action.name, action.page, action.limit).pipe(
          mergeMap((res: any) => {
            //console.log(res)
            const comments = getComments(res.posts);
            return [upsertPostsByGroupSucsess({ posts: res.posts }),
              loadGroupPostsData({ totalPosts: res.totalPosts, currentPostPage: res.currentPage, group: action.name }),
              upsertPostCommentsSuccess({ postComments: comments })];
          }),
          catchError(e => {
            return [];
          })
        )
      )
    );
  });

  loadUserPosts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(upsertPostsByUser),
      switchMap((action) =>
        this.userProfileService.getUserPosts(action.userName, action.page, action.limit
          , action.postType).pipe(
          mergeMap((res: any) => {
            //console.log(res)
            const comments = getComments(res.posts);
            return [
              upsertPostsSucsess({ posts: res.posts }),
              loadUserPostsData({
                totalPosts: res.totalPosts,
                currentPostPage: res.currentPage, id: res._id,
                postType: action.postType
              }),
              upsertPostCommentsSuccess({ postComments: comments })];
          }),
          catchError(e => {
            return [];
          })
        )
      )
    );
  });

  upsertSingle$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(upsertPost),
      switchMap((action) =>
        this.postService.getSinglePost(action.post).pipe(
          mergeMap((res: any) => {
            const comments = getComments(res);
            return [upsertPostSucsess({ post: res[0] }),
              upsertPostCommentsSuccess({ postComments: comments })];
          }),
          catchError(e => {
            //  console.log('HHHH',e)
            return [upsertPostFailed({ post: e.error })];
          })
        )
      )
    );
  });

  // upsertFeedPosts$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(upsertMainFeedPosts),
  //     switchMap((action) =>
  //       this.postService.getMainFeed(action.page, action.limit).pipe(
  //         mergeMap((res: any) => {
  //           //console.log(res)
  //           const comments=this.getComments(res.posts)
  //           return [upsertPostsSucsess({ posts: res.posts }),
  //             UpdateFeedData({
  //               ids: res.posts.map((p: any) => p._id),
  //               currentPage: res.currentPage,
  //               totalPosts: res.totalPosts
  //             }),
  //             upsertPostCommentsSuccess({ postComments: comments })];
  //         }),
  //         catchError(e => {
  //           console.log(e)
  //           return [];
  //         })
  //       )
  //     )
  //   );
  // });

  searchPost$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(upsertSearchResultPosts),
      switchMap((action) =>
        this.postService.searchPost(action.page, action.limit, action.globalSearch).pipe(
          map((res: any) => {
            //console.log(res)
            return upsertSearchResultPostsSucsess({
              posts: res.posts,
              totalPosts: res.totalPosts,
              currentPage: res.currentPage
            });
          }),
          catchError(e => {
            return [];
          })
        )
      )
    );
  });


  constructor(private actions$: Actions,
              private tagService: TagService,
              private postService: PostService,
              private userProfileService: UserProfileService,
              private groupService: GroupService
  ) {
  }
}
