import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { URLS } from "../../../environments/environment";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class PostService {

  constructor(private http: HttpClient) {
  }

  createNewPost(description: string, link: string, price: string, currency: string,
                morePriceOption: boolean,morePriceOptionValue: string, couponCode: string, images: string[],
                tagImages: string[], freeText: string, freeTextOption: boolean,addToGroup:string|null) {
    let params: any = {
      link: link, price: price, currency: currency
    };
    if (description) {
      params = { ...params, description: description };
    }if (addToGroup) {
      params = { ...params, addToGroup: addToGroup };
    }
    if (morePriceOption) {
      params = { ...params, morePriceOption: morePriceOptionValue };
    }
    if (freeTextOption) {
      params = { ...params, freeText: freeText };
    }
    if (couponCode) {
      params = { ...params, couponCode: couponCode };
    }
    if (images) {
      params = { ...params, images: images };
    } if (price) {
      params = { ...params, price: price };
    }
    if (tagImages) {
      params = { ...params, tagImages: [tagImages] };
    }
    console.log(params)
    return this.http.post(`${URLS.API}post/create-new-post`, {
      ...params
    }).pipe(map((res: any) => {
      return res.data;
    }));
  }

  editPost(description: string, link: string, price: string, currency: string,
           morePriceOption:string,  couponCode: string, images: string[], post: string,
           tagImages: string[],freeText:string) {
    let params: any = {
      description, couponCode, post, link: link, price: price, currency: currency,
      freeText,morePriceOption
    };
    if (images) {
      params = { ...params, images: images };
    }
    if (tagImages) {
      params = { ...params, tagImages: [tagImages] };
    }
    // console.log(params)
    return this.http.post(`${URLS.API}post/edit-post`, {
      ...params
    }).pipe(map((res: any) => {
      return res.data;
    }));
  }

  removePost(post: string) {
    return this.http.post(`${URLS.API}post/delete-post`, {
      post
    });
  }

  uploadPostImage(blobUrl: string) {
    return this.http.post(`${URLS.API}post/upload-post-image`, {
      blobUrl: blobUrl
    });
  }

  addOrRemovePostReaction(post: string, reaction: string,status:boolean) {
    return this.http.post(`${URLS.API}post-reaction/add-or-remove-post-reaction`, {
      post: post, reaction: reaction,status
    }).pipe(map((res: any) => {
      return res.data;
    }));
  }
  addActionOnPost(post: string,type:string) {
    return this.http.post(`${URLS.API}post/add-profile-action-on-post`, {
      post,type
    })
  }

  addPostComment(post: string, comment: string) {
    return this.http.post(`${URLS.API}post-comment/add-post-comment`, {
      post: post, comment: comment
    }).pipe(map((res: any) => {
      return res.data;
    }));
  }

  editPostComment(commentId: string, newComment: string) {
    return this.http.post(`${URLS.API}post-comment/edit-post-comment`, {
      commentId, newComment
    });
  }

  deletePostComment(commentId: string) {
    return this.http.post(`${URLS.API}post-comment/delete-post-comment`, {
      commentId
    });
  }

  getSinglePost(post: string, page: number = 0, limit: number = 1) {
    return this.http.get(`${URLS.API}post/get-single-post`, {
      params: {
        post, page, limit
      }
    }).pipe(map((res: any) => {
      return res.data;
    }));
  }

  getPostToEdit(post: string) {
    return this.http.get(`${URLS.API}post/get-post-to-edit`, {
      params: {
        post
      }
    }).pipe(map((res: any) => {
      return res.data;
    }));
  }

  getPostComments(post: string, page: number, limit: number) {
    return this.http.get(`${URLS.API}post-comment/get-post-comment`, {
      params: {
        post, page, limit
      }
    }).pipe(map((res: any) => {
      return res.data;
    }));
  }

  getFlags(flagOf: string) {
    return this.http.get(`${URLS.API}salez-flag/get-flags`, {
      params: {
        flagOf
      }
    }).pipe(map((res: any) => {
      return res.data;
    }));
  }

  addFlag(flag: string, element: string, flagOf: string) {
    return this.http.post(`${URLS.API}user-flag/add-flag`, {
      flag, element, flagOf
    });
  }

  getMainFeed(page: number, limit: number) {
    return this.http.get(`${URLS.API}post/get-main-feed`, {
      params: {
        page, limit
      }
    }).pipe(map((res: any) => {
      return res.data;
    }));
  }
  getFeedLayout1(page: number) {
    return this.http.get(`${URLS.API}post/get-feed-layout1`, {
      params: {
        page
      }
    }).pipe(map((res: any) => {
      return res.data;
    }));
  }  getFeedLayout2(page: number) {
    return this.http.get(`${URLS.API}post/get-feed-layout2`, {
      params: {
        page
      }
    }).pipe(map((res: any) => {
      return res.data;
    }));
  }

  searchPost(page: number, limit: number, globalSearch: string) {
    return this.http.get(`${URLS.API}post/search-post`, {
      params: {
        page, limit, globalSearch
      }
    }).pipe(map((res: any) => {
      return res.data;
    }));
  }

  getDataFromProductLink(url: string) {
    return this.http.get(`${URLS.API}post/get-data-from-product-link`, {
      params: {
        url
      }
    }).pipe(map((res: any) => {
      return res.data;
    }));
  }
}
