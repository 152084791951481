import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { URLS } from "../../../../environments/environment";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ProfileSettingService {

  constructor(private http: HttpClient) {
  }

  getNotificationSettings() {
    return this.http.get(`${URLS.API}profile-setting/get-notification-settings`)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  changeSpecificNot(setting: string, value: string[]) {
    return this.http.post(`${URLS.API}profile-setting/change-specific-notification-setting`, {
      setting, value
    })
  }
}
