import { createReducer, createSelector, on } from "@ngrx/store";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { Tag } from "./tag.model";
import * as TagActions from "./tag.actions";
import { AppState } from "../../reducers";
import { Post } from "../post/post.model";
import { postsFeatureKey } from "../post/post.reducer";

export const tagsFeatureKey = "tags";

export interface State extends EntityState<Tag> {
  popularTags: any[];
  loadedPopularTags: boolean;
  loadingPopularTags: boolean;
  totalPopularTags: number;
  currentPagePopularTags: number;

  totalSearchResultTag: number,
  currentSearchResultPage: number,

}

export const adapter: EntityAdapter<Tag> = createEntityAdapter<Tag>({
  selectId: (t: Tag) => t.slug
});

export const initialState: State = adapter.getInitialState({
  popularTags: [],
  loadedPopularTags: false,
  loadingPopularTags: false,
  currentPagePopularTags: 0,
  totalPopularTags: 0,

  totalSearchResultTag: 0,
  currentSearchResultPage: 0
});

export const reducer = createReducer(
  initialState,
  on(TagActions.addTag,
    (state, action) => adapter.addOne(action.tag, state)
  ),

  on(TagActions.upsertTagSucsess,
    (state, action) => adapter.upsertOne(action.tag, state)
  ),
  on(TagActions.addTags,
    (state, action) => adapter.addMany(action.tags, state)
  ),
  on(TagActions.upsertPopularTagsSucsess,
    (state, action) => adapter.upsertMany(action.tags, {
      ...state,
      popularTags: action.ids,
      loadedPopularTags: true,
      loadingPopularTags: false,
      currentPagePopularTags: action.currentPage,
      totalPopularTags: action.total
    })
  ), on(TagActions.upsertTagsSearchResultSucsess,
    (state, action) => adapter.upsertMany(action.tags, {
      ...state,
      totalSearchResultTag: action.total,
      currentSearchResultPage: action.currentPage
    })
  ),
  on(TagActions.upsertTagsSucsess,
    (state, action) => adapter.upsertMany(action.tags, {
      ...state
    })
  ),
  on(TagActions.upsertPopularTags, (state, action) => {
    return {
      ...state,
      loadedPopularTags: false, loadingPopularTags: true
    };
  }),
  on(TagActions.updateTag,
    (state, action) =>

      adapter.updateOne({
        id: action.id,
        changes: { ...action.changes }
      }, state)
  ),
  on(TagActions.updateTags,
    (state, action) => adapter.updateMany(action.tags, state)
  ),
  on(TagActions.deleteTag,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(TagActions.deleteTags,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(TagActions.loadTags,
    (state, action) => adapter.setAll(action.tags, state)
  ),

  on(TagActions.loadTagPostsData,
    (state, action) => {
      let changes: any = {
        totalPosts: action.totalPosts.length,
        currentPostPage: action.currentPostPage,
        loadedPosts: true,
        loadingPosts: false
      };
      if (action.postType === "liked") {
        changes = {
          totalLikedPosts: action.totalPosts.length,
          currentLikedPostPage: action.currentPostPage,
          loadedLikedPosts: true,
          loadingLikedPosts: false,
          likedPosts:action.totalPosts
        };
      }
      return adapter.updateOne({
        id: action.tagSlug,
        changes: {
          ...changes
        }
      }, state);
    }
  ),
  on(TagActions.upsertPostsByTag,
    (state, action) => {
      let changes: any = {
        loadedPosts: false, loadingPosts: true
      };
      if (action.postType == "liked") {
        changes = { loadedLikedPosts: false, loadingLikedPosts: true };
      }
      return adapter.updateOne({
        id: action.slug,
        changes: { ...changes }
      }, state);
    }
  ),
  on(TagActions.upsertTagFollowers,
    (state, action) => {
      return adapter.updateOne({
        id: action.slug,
        changes: { loadedFollowers: false, loadingFollowers: true }
      }, state);
    }
  ),
  on(TagActions.loadTagFollowersData,
    (state, action) => {
      return adapter.updateOne({
        id: action.tagSlug,
        changes: {
          totalFollowers: action.totalFollowers,
          currentFollowPage: action.currentFollowPage,
          loadedFollowers: true, loadingFollowers: false
        }
      }, state);
    }
  ),
  on(TagActions.clearTags,
    state => adapter.removeAll(state)
  )
);

export const SelectTagFeature = (state: AppState) => state[tagsFeatureKey];

export const SelectPopularTags = (state: AppState) => {
  return {
    ids: state[tagsFeatureKey].popularTags,
    load: state[tagsFeatureKey].loadedPopularTags,
    loading: state[tagsFeatureKey].loadingPopularTags,
    total: state[tagsFeatureKey].totalPopularTags,
    currentPage: state[tagsFeatureKey].currentPagePopularTags
  };
};

export const SelectSearchResultTagsData = (state: AppState) => {
  return {
    currentPage: state[tagsFeatureKey].currentSearchResultPage,
    total: state[tagsFeatureKey].totalSearchResultTag
  };
};


export const SelectTagBySlug = (slug: string) => createSelector(
  selectAll,
  (entities: Tag[]) =>
    entities.find(g => g.slug === slug)
);

export const SelectTagsByIds = (ids: string[]) => createSelector(
  selectAll,
  (entities: Tag[]) => entities.filter(g => ids?.includes(g._id))
);
export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors(SelectTagFeature);
