import { createAction, createActionGroup, emptyProps, props } from "@ngrx/store";
import { Update } from "@ngrx/entity";

import { PostComment } from "./post-comment.model";

export const loadPostComments = createAction(
  "[PostComment/API] Load PostComments",
  props<{ postComments: PostComment[] }>()
);

export const addPostComment = createAction(
  "[PostComment/API] Add PostComment",
  props<{ postComment: PostComment }>()
);

export const upsertPostComment = createAction(
  "[PostComment/API] Upsert PostComment",
  props<{ postComment: PostComment }>()
);

export const addPostComments = createAction(
  "[PostComment/API] Add PostComments",
  props<{ postComments: PostComment[] }>()
);



export const upsertPostCommentsSuccess = createAction(
  "[PostComment/API] Upsert PostComments Success",
  props<{ postComments: PostComment[] }>()
);

export const updatePostComment = createAction(
  "[PostComment/API] Update PostComment",
  props<{ id:string,changes:any }>()
);

export const updatePostComments = createAction(
  "[PostComment/API] Update PostComments",
  props<{ postComments: Update<PostComment>[] }>()
);

export const deletePostComment = createAction(
  "[PostComment/API] Delete PostComment",
  props<{ id: string }>()
);

export const deletePostComments = createAction(
  "[PostComment/API] Delete PostComments",
  props<{ ids: string[] }>()
);

export const clearPostComments = createAction(
  "[PostComment/API] Clear PostComments"
);

