import { Injectable } from "@angular/core";
import { URLS } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class GroupService {

  constructor(private http: HttpClient) {
  }

  validateGroupName(name: string, group?: string) {
    let params: any = { name };
    if (group) {
      params = { ...params, group };
    }
    return this.http.get(`${URLS.API}group/validate-group-name`, {
      params: {
        ...params
      }
    });
  }

  addGroupImage(blobUrl: string, group?: string) {
    return this.http.post(`${URLS.API}group/add-group-image`, {
      blobUrl, group
    });
  }
  profileActionOnGroup( group: string,type:string) {
    return  this.http.post(`${URLS.API}group/profile-action-on-group`, {
      group,type
    });
  }

  removeGroupImage(group: string) {
    return this.http.post(`${URLS.API}group/remove-group-image`, {
      group
    });
  }

  createGroup(name: string, description: string, uniqueGroupName: string, images: string[], type: string) {
    let params: any = {
      name, uniqueGroupName, type
    };
    if (description) {
      params = { ...params, description };
    }
    if (images) {
      params = { ...params, images };
    }
    return this.http.post(`${URLS.API}group/create-group`, {
      ...params
    });
    //   .pipe(map((res: any) => {
    //   return res.data;
    // }));
  }

  getGroupData(name: string) {
    return this.http.get(`${URLS.API}group/get-group-data`, {
      params: {
        name
      }
    }).pipe(map((res: any) => {
      return res.data;
    }));
  }
  getGroups(page: number,limit:number,groupType:string,globalSearch:string|null) {
    let params:any={ page,limit,groupType}
    if(globalSearch)
    {
      params={...params,globalSearch}
    }
    return this.http.get(`${URLS.API}group/get-groups`, {
      params: {
       ...params
      }
    }).pipe(map((res: any) => {
      return res.data;
    }));
  }

  removeGroupMember(group: string, member: string, removePosts: boolean) {
    return this.http.post(`${URLS.API}group/remove-group-member`, {
      group, member, removePosts
    });
  }

  removePostFromGroup(group: string, post: string) {
    return this.http.post(`${URLS.API}group/remove-post-from-group`, {
      group, post
    });
  }

  getGroupToEdit(name: string) {
    return this.http.get(`${URLS.API}group/get-group-to-edit`, {
      params: {
        name
      }
    }).pipe(map((res: any) => {
      return res.data;
    }));
  }

  getUserGroupList(page: number, limit: number, globalSearch: string | null) {
    let params: any = {
      page, limit
    };
    if (globalSearch) {
      params = { ...params, globalSearch };
    }
    return this.http.get(`${URLS.API}group/get-user-group-list`, {
      params: {
        ...params
      }
    }).pipe(map((res: any) => {
      return res.data;
    }));
  }

  getGroupPosts(name: string, page: number, limit: number) {
    let params: any = {
      name, page, limit
    };
    return this.http.get(`${URLS.API}group/get-group-posts`, {
      params: {
        ...params
      }
    }).pipe(map((res: any) => {
      return res.data;
    }));
  }

  getGroupMembers(name: string, page: number, limit: number) {
    let params: any = {
      name, page, limit
    };
    return this.http.get(`${URLS.API}group/get-group-members`, {
      params: {
        ...params
      }
    }).pipe(map((res: any) => {
      return res.data;
    }));
  }

  getGroupRequests(name: string, page: number, limit: number) {
    let params: any = {
      name, page, limit
    };
    return this.http.get(`${URLS.API}group-request/get-group-requests`, {
      params: {
        ...params
      }
    }).pipe(map((res: any) => {
      return res.data;
    }));
  }

  editGroup(description: string, name: string, uniqueGroupName: string, type: string, group: string) {
    let params: any = {
      description, type, group, name, uniqueGroupName

    };
    //console.log(params);
    return this.http.post(`${URLS.API}group/edit-group`, {
      ...params
    });
  }

  joinToPublicGroup(group: string) {
    return this.http.post(`${URLS.API}group/join-to-public-group`, {
      group
    }).pipe(map((res: any) => {
      return res.data;
    }));
  }

  leaveGroup(group: string) {
    return this.http.post(`${URLS.API}group/leave-group`, {
      group
    }).pipe(map((res: any) => {
      return res.data;
    }));
  }

  confirmOrRejectGroupRequest(group: string,status:string,request:string) {
    return this.http.post(`${URLS.API}group-request/confirm-or-reject-group-request`, {
      group,status,request
    })
  }
  cancelGroupRequest(request:string) {
    return this.http.post(`${URLS.API}group-request/cancel-group-request`, {
      request
    })
  }

  addPostToGroups(post: string, groups: string[]) {
    return this.http.post(`${URLS.API}group/add-post-to-groups`, {
      groups, post
    }).pipe(map((res: any) => {
      return res.data;
    }));
  }

  addPrivateGroupRequest(group: string) {
    // console.log(group)
    return this.http.post(`${URLS.API}group-request/add-private-group-request`, {
      group
    }).pipe(map((res: any) => {
      return res.data;
    }));
  }
}
