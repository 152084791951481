import { NgModule, APP_INITIALIZER } from "@angular/core";
import { BrowserModule, provideClientHydration } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, provideHttpClient, withFetch } from "@angular/common/http";
import { HttpClientInMemoryWebApiModule } from "angular-in-memory-web-api";
import { ClipboardModule } from "ngx-clipboard";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { InlineSVGModule } from "ng-inline-svg-2";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthService } from "./modules/auth/services/auth.service";
import { environment, URLS } from "src/environments/environment";

// #fake-start#
import { FakeAPIService } from "./_fake/fake-api.service";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { JwtInterceptor } from "./modules/auth/interceptors/jwt.interceptor";
import { StoreModule } from "@ngrx/store";
import { metaReducers, reducers } from "./reducers";
import { EffectsModule } from "@ngrx/effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { GeneralStateEffects } from "./state/general-state/general-state.effects";
import { PostEffects } from "./state/post/post.effects";
import { TagEffects } from "./state/tag/tag.effects";
import { UserProfileEffects } from "./state/user-profile/user-profile.effects";
import { PostCommentEffects } from "./state/post-comment/post-comment.effects";
import { GroupEffects } from "./state/group/group.effects";
import { GroupRequestEffects } from "./state/group-request/group-request.effects";
import { NotificationEffects } from "./state/notification/notification.effects";


// #fake-end#

function appInitializer(authService: AuthService) {
  return () => {
    return new Promise((resolve) => {
      //@ts-ignore
      authService.getUserByToken().subscribe().add(resolve);
    });
  };
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, URLS.API + "system-text/get-system-texts/", ".json");
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    ClipboardModule,
    // #fake-start#
    environment.isMockEnabled
      ? HttpClientInMemoryWebApiModule.forRoot(FakeAPIService, {
        passThruUnknownUrl: true,
        dataEncapsulation: false
      })
      : [],
    // #fake-end#
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    NgbModule,
    StoreModule.forRoot(reducers, {
      metaReducers
    }),
    EffectsModule.forRoot([GeneralStateEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production // Restrict extension to log-only mode
    }),
    TranslateModule.forRoot({
      defaultLanguage: "he",
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    EffectsModule.forFeature([
      GeneralStateEffects,
      PostEffects,
      TagEffects,
      UserProfileEffects,
      PostCommentEffects,
      GroupEffects,
      GroupRequestEffects,
      NotificationEffects
    ])
  ],
  // providers: [
  //   {
  //     provide: APP_INITIALIZER,
  //     useFactory: appInitializer,
  //     multi: true,
  //     deps: [AuthService],
  //   },
  // ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    provideClientHydration()
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
