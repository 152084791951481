import { createFeature, createReducer, createSelector, on } from "@ngrx/store";
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { GroupRequest } from './group-request.model';
import * as GroupRequestActions from "./group-request.actions";
import { AppState } from "../../reducers";


export const groupRequestsFeatureKey = 'groupRequests';

export interface State extends EntityState<GroupRequest> {
  // additional entities state properties
}

export const adapter: EntityAdapter<GroupRequest> = createEntityAdapter<GroupRequest>({
  selectId: (g: GroupRequest) => g._id
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export const reducer = createReducer(
  initialState,
  on(GroupRequestActions.addGroupRequest,
    (state, action) => adapter.addOne(action.groupRequest, state)
  ),
  on(GroupRequestActions.upsertGroupRequest,
    (state, action) => adapter.upsertOne(action.groupRequest, state)
  ),
  on(GroupRequestActions.addGroupRequests,
    (state, action) => adapter.addMany(action.groupRequests, state)
  ),
  on(GroupRequestActions.upsertGroupRequestsSuccess,
    (state, action) => adapter.upsertMany(action.groupRequests, state)
  ),
  on(GroupRequestActions.updateGroupRequest,
    (state, action) => adapter.updateOne(action.groupRequest, state)
  ),
  on(GroupRequestActions.updateGroupRequests,
    (state, action) => adapter.updateMany(action.groupRequests, state)
  ),
  on(GroupRequestActions.deleteGroupRequest,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(GroupRequestActions.deleteGroupRequests,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(GroupRequestActions.loadGroupRequests,
    (state, action) => adapter.setAll(action.groupRequests, state)
  ),
  on(GroupRequestActions.clearGroupRequests,
    state => adapter.removeAll(state)
  ),
);

export const SelectGroupRequestFeature = (state: AppState) => state[groupRequestsFeatureKey];
export const SelectRequestsByIds = (ids: string[]) => createSelector(
  selectAll,
  (entities: GroupRequest[]) => entities.filter(u => ids?.includes(u._id))   .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors(SelectGroupRequestFeature);
