import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ThemeModeComponent } from "../../../kt/layout";
import { isPlatformBrowser } from "@angular/common";

export type ThemeModeType = "dark" | "light" | "system";
const systemMode = ThemeModeComponent.getSystemMode() as "light" | "dark";
// const themeModeSwitchHelper = (_mode: ThemeModeType) => {
//   // change background image url
//   const mode = _mode !== "system" ? _mode : systemMode;
//   const imageUrl =
//     "./assets/media/patterns/header-bg" +
//     (mode === "light" ? ".jpg" : "-dark.png");
//   document.body.style.backgroundImage = `url("${imageUrl}")`;
// };

const themeModeLSKey = "kt_theme_mode_value";
const themeMenuModeLSKey = "kt_theme_mode_menu";

const getThemeModeFromLocalStorage = (lsKey: string): ThemeModeType => {
  const hasLocal = typeof localStorage !== "undefined";
  if (!hasLocal) {
    return "light";
  }

  const data = hasLocal ? localStorage.getItem(lsKey) : null;
  if (!data) {
    return "light";
  }

  if (data === "light") {
    return "light";
  }

  if (data === "dark") {
    return "dark";
  }

  return "system";
};

@Injectable({
  providedIn: "root"
})
export class ThemeModeService {
  public mode: BehaviorSubject<ThemeModeType> =
    new BehaviorSubject<ThemeModeType>(
      getThemeModeFromLocalStorage(themeModeLSKey)
    );
  public menuMode: BehaviorSubject<ThemeModeType> =
    new BehaviorSubject<ThemeModeType>(
      getThemeModeFromLocalStorage(themeMenuModeLSKey)
    );
  hasLocal: boolean;
  hasDocument:boolean;

  constructor( @Inject(PLATFORM_ID) private platformId: Object, ) {
    if(isPlatformBrowser(platformId))
    {this.hasLocal = typeof localStorage !== "undefined";
      this.hasDocument = typeof document !== "undefined";

    }

  }

  public updateMode(_mode: ThemeModeType) {
    const updatedMode = _mode === "system" ? systemMode : _mode;
    this.mode.next(updatedMode);
    // themeModeSwitchHelper(updatedMode)
    if(isPlatformBrowser(this.platformId))
    {
      if (this.hasLocal) {
        localStorage.setItem(themeModeLSKey, updatedMode);
      }
      if (document){
        document.documentElement.setAttribute("data-bs-theme", updatedMode);
      }
      if (document){
        ThemeModeComponent.init();
      }
    }


  }

  public updateMenuMode(_menuMode: ThemeModeType) {
    this.menuMode.next(_menuMode);
    if (this.hasLocal) {
      localStorage.setItem(themeMenuModeLSKey, _menuMode);
    }
  }

  public init() {
    this.updateMode(this.mode.value);
    this.updateMenuMode(this.menuMode.value);
  }

  public switchMode(_mode: ThemeModeType) {
    if(isPlatformBrowser(this.platformId))
    {
      if (this.hasLocal) {
        const updatedMode = _mode === "system" ? systemMode : _mode;
        localStorage.setItem(themeModeLSKey, updatedMode);
        localStorage.setItem(themeMenuModeLSKey, _mode);
      }
      if (this.hasDocument)
        document.location.reload();
    }

  }
}
