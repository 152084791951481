import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { URLS } from "../../../environments/environment";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  constructor(private http: HttpClient) {
  }
  getUserProfiles(page: number, limit: number, globalSearch: string, type: string) {
    return this.http.get(`${URLS.API}user-profile/get-user-profiles`,
      { params: { globalSearch, page, limit, type: type } })
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  getPublicUserProfile(userProfileName:string)
  {
    return this.http.get(`${URLS.API}user-profile/get-public-user-profile`,
      { params: {userProfileName } })
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  getProfileSettingTabs()
  {
    return this.http.get(`${URLS.API}user-profile/get-profile-setting-tabs`)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  getUserPosts(userName:string,page:number,limit:number,postType:string)
  {
    return this.http.get(`${URLS.API}user-profile/get-user-posts`,
      { params: {userName,page,limit,postType } })
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  getTagByUser(userName: string, page: number, limit: number) {
    return this.http.get(`${URLS.API}user-profile/get-user-tags`, { params: { userName: userName, page: page, limit: limit } })
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  getFollowersByUser(userName: string, page: number, limit: number,type:string) {
    return this.http.get(`${URLS.API}user-profile/get-user-followers`,
      { params: { userName: userName, page: page, limit: limit,type:type } })
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  addOrRemoveFollow(profile: string, value: boolean) {
    return this.http.post(`${URLS.API}user-profile/add-or-remove-follow`, {
      profile, value
    }).pipe(map((res:any)=>{
      return res.data
    }));
  }

  addProfileAction(profileUserName: string, type: string) {
    return this.http.post(`${URLS.API}user-profile/add-profile-action-on-profile`, {
      profileUserName, type
    })
  }

  getUserGroupRequest(page:number,limit:number)
  {
    return this.http.get(`${URLS.API}user-profile/get-user-group-request`,
      { params: {page,limit } })
      .pipe(map((res: any) => {
        return res.data;
      }));
  }
}
