import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { URLS } from "../../../environments/environment";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class TagService {

  constructor(private http: HttpClient) {
  }

  getTags(globalSearch: string | null, limit: number, page: number) {
    let params: any = { limit, page };
    if (globalSearch) {
      params = { ...params, globalSearch };
    }
    return this.http.get(`${URLS.API}tag/get-tags`,
      { params: { ...params } })
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  getTagData(slug: string) {
    return this.http.get(`${URLS.API}tag/get-tag-data`, { params: { slug: slug } })
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  getTagPosts(slug: string, page: number, limit: number,postType:string) {
    return this.http.get(`${URLS.API}tag/get-tag-posts`, { params: {
      slug: slug, page: page, limit: limit,postType } })
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  getTagFollowers(slug: string, page: number, limit: number) {
    return this.http.get(`${URLS.API}tag/get-tag-followers`, { params: { slug: slug, page: page, limit: limit } })
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  getTagSuggestions(search:string) {
    return this.http.get(`${URLS.API}tag/get-tag-suggestions`,
      { params: {
      search:search } })
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  addOrRemoveTagFollow(tag: string, value: boolean) {
    return this.http.post(`${URLS.API}tag/add-or-remove-tag-follow`, {
      tag, value
    }).pipe(map((res: any) => {
      return res.data;
    }));
  }

  actionOnTag(tag: string, type: string) {
    return this.http.post(`${URLS.API}tag/add-profile-action-on-tag`, {
      tag, type
    })
  }
}
