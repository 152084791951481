import { createAction, createActionGroup, emptyProps, props } from "@ngrx/store";
import { Update } from "@ngrx/entity";

import { Group } from "./group.model";

export const loadGroups = createAction(
  "[Group/API] Load Groups",
  props<{ groups: Group[] }>()
);

export const addGroup = createAction(
  "[Group/API] Add Group",
  props<{ group: Group }>()
);


export const upsertGroup = createAction(
  "[Group/API] Upsert Group",
  props<{ name: string }>()
);

export const upsertGroupSucsess = createAction(
  "[Group/API] Upsert Group sucsess",
  props<{ group: Group }>()
);

export const upsertGroupFailed = createAction(
  "[Group/API] Upsert Group Failed",
  props<{ group: Group }>()
);

export const upsertPostsByGroup = createAction(
  "[Group/API] Upsert Post By Group",
  props<{ name: string, page: number, limit: number }>()
);

export const loadGroupPostsData = createAction(
  "[Group/API] Load group Posts Data",
  props<{ totalPosts: number, currentPostPage: number, group: string }>()
);
export const upsertGroupMembers = createAction(
  "[Group/API] Upsert Group Members",
  props<{ page: number, limit: number, name: string }>()
);
export const loadGroupMembersData = createAction(
  "[Group/API] Load Group Members Data",
  props<{ totalMembers: number, currentMemberPage: number, name: string }>()
);

export const upsertGroupRequests = createAction(
  "[Group/API] Upsert Group requests",
  props<{ page: number, limit: number, name: string }>()
);
export const loadGroupRequestsData = createAction(
  "[Group/API] Load Group requests Data",
  props<{ totalRequests: number, currentRequestPage: number, name: string }>()
);
export const addGroups = createAction(
  "[Group/API] Add Groups",
  props<{ groups: Group[] }>()
);

export const upsertGroupList = createAction(
  "[Group/API] Upsert Group List",
  props<{ page: number, limit: number, globalSearch: string | null }>()
);

export const upsertGroupListSuccess = createAction(
  "[Group/API] Upsert Group List Success",
  props<{ groups: Group[], total: number, currentPage: number, ids: string[] }>()
);

export const upsertPublicGroups = createAction(
  "[Group/API] Upsert Public Groups",
  props<{ page: number, limit: number, globalSearch: string | null, groupType: string }>()
);
export const upsertPublicGroupsSuccess = createAction(
  "[Group/API] Upsert Public Groups Success",
  props<{ groups: Group[], total: number, currentPage: number, ids: string[] }>()
);

export const upsertPrivateGroups = createAction(
  "[Group/API] Upsert Private Groups",
  props<{ page: number, limit: number, globalSearch: string | null, groupType: string }>()
);
export const upsertPrivateGroupsSuccess = createAction(
  "[Group/API] Upsert Private Groups Success",
  props<{ groups: Group[], total: number, currentPage: number, ids: string[] }>()
);
export const upsertPrivateGroupsFailed = createAction(
  "[Group/API] Upsert Private Groups Failed",
);
export const updateGroup = createAction(
  "[Group/API] Update Group",
  props<{ id: string, changes: any }>()
);
export const addGroupPost = createAction(
  "[Group/API] Add Group Post",
  props<{ id: string, post: string }>()
);

export const updateGroups = createAction(
  "[Group/API] Update Groups",
  props<{ groups: Update<Group>[] }>()
);

export const updateGroupListIds = createAction(
  "[Group/API] Update Group List Ids ",
  props<{ id: string }>()
);

export const deleteGroup = createAction(
  "[Group/API] Delete Group",
  props<{ id: string }>()
);

export const deleteGroups = createAction(
  "[Group/API] Delete Groups",
  props<{ ids: string[] }>()
);

export const clearGroups = createAction(
  "[Group/API] Clear Groups");

