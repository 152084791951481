import { isDevMode } from '@angular/core';
import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import { environment } from "../../environments/environment";
import * as fromGeneralState from '../state/general-state/general-state.reducer';
import * as fromPostState from '../state/post/post.reducer';
import * as fromTagState from '../state/tag/tag.reducer';
import * as fromUserProfile from '../state/user-profile/user-profile.reducer';
import * as fromPostComment from '../state/post-comment/post-comment.reducer';
import * as fromGroup from '../state/group/group.reducer';
import * as fromGroupRequest from '../state/group-request/group-request.reducer';
import * as fromNotification from '../state/notification/notification.reducer';





export interface AppState {
  [fromGeneralState.generalStateFeatureKey]: fromGeneralState.State;
  [fromPostState.postsFeatureKey]: fromPostState.State;
  [fromTagState.tagsFeatureKey]: fromTagState.State;
  [fromUserProfile.userProfilesFeatureKey]: fromUserProfile.State;
  [fromPostComment.postCommentsFeatureKey]: fromPostComment.State;
  [fromGroup.groupsFeatureKey]: fromGroup.State;
  [fromGroupRequest.groupRequestsFeatureKey]: fromGroupRequest.State;
  [fromNotification.notificationsFeatureKey]: fromNotification.State;
}

export const reducers: ActionReducerMap<AppState,any> = {
  [fromGeneralState.generalStateFeatureKey]: fromGeneralState.reducer,
  [fromPostState.postsFeatureKey]: fromPostState.reducer,
  [fromTagState.tagsFeatureKey]: fromTagState.reducer,
  [fromUserProfile.userProfilesFeatureKey]: fromUserProfile.reducer,
  [fromPostComment.postCommentsFeatureKey]: fromPostComment.reducer,
  [fromGroup.groupsFeatureKey]: fromGroup.reducer,
  [fromGroupRequest.groupRequestsFeatureKey]: fromGroupRequest.reducer,
  [fromNotification.notificationsFeatureKey]: fromNotification.reducer,
};


export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];
