import { createReducer, createSelector, on } from "@ngrx/store";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { Group } from "./group.model";
import * as GroupActions from "./group.actions";
import { AppState } from "../../reducers";


export const groupsFeatureKey = "groups";

export interface State extends EntityState<Group> {
  // additional entities state properties
  groupList: string[],
  loadedGroupList: boolean,
  loadingGroupList: boolean,
  currentPageGroupList: number;
  totalGroups: number;

  publicGroups: string[];
  loadedPublicGroups: boolean,
  loadingPublicGroups: boolean,
  currentPublicGroupPage: number;
  totalPublicGroups: number;

  privateGroups: string[];
  loadedPrivateGroups: boolean,
  loadingPrivateGroups: boolean,
  currentPrivateGroupPage: number;
  totalPrivateGroups: number;
}

export const adapter: EntityAdapter<Group> = createEntityAdapter<Group>({
  selectId: (g: Group) => g.uniqueGroupName
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  groupList: [],
  loadedGroupList: false,
  loadingGroupList: false,
  currentPageGroupList: 0,
  totalGroups: 0,
  publicGroups: [],
  loadedPublicGroups: false,
  loadingPublicGroups: false,
  currentPublicGroupPage: 0,
  totalPublicGroups: 0,
  privateGroups: [],
  loadedPrivateGroups: false,
  loadingPrivateGroups: false,
  currentPrivateGroupPage: 0,
  totalPrivateGroups: 0
});

export const reducer = createReducer(
    initialState,
    on(GroupActions.addGroup,
      (state, action) => adapter.addOne(action.group, state)
    ),
    on(GroupActions.upsertGroupSucsess,
      (state, action) => adapter.upsertOne(action.group, state)
    ),
    on(GroupActions.upsertGroupFailed,
      (state, action) => adapter.upsertOne(action.group, state)
    ),

    on(GroupActions.upsertPostsByGroup,
      (state, action) => {
        return adapter.updateOne({
          id: action.name,
          changes: { loadedPosts: false, loadingPosts: true }
        }, state);
      }
    ),
    on(GroupActions.loadGroupPostsData,
      (state, action) => {
        return adapter.updateOne({
          id: action.group,
          changes: {
            totalPosts: action.totalPosts,
            currentPostPage:
            action.currentPostPage,
            loadedPosts: true,
            loadingPosts: false
          }
        }, state);
      }
    ),
    on(GroupActions.upsertGroupMembers,
      (state, action) => {
        return adapter.updateOne({
          id: action.name,
          changes: { loadedMembers: false, loadingMembers: true }
        }, state);
      }
    ),
    on(GroupActions.loadGroupMembersData,
      (state, action) => {
        return adapter.updateOne({
          id: action.name,
          changes: {
            totalMembers: action.totalMembers,
            currentMemberPage: action.currentMemberPage,
            loadedMembers: true, loadingMembers: false
          }
        }, state);
      }
    ),
    on(GroupActions.loadGroupRequestsData,
      (state, action) => {
        return adapter.updateOne({
          id: action.name,
          changes: {
            totalRequests: action.totalRequests,
            currentRequestPage: action.currentRequestPage
          }
        }, state);
      }
    ),

    on(GroupActions.addGroups,
      (state, action) => adapter.addMany(action.groups, state)
    ),

    on(GroupActions.upsertGroupList, (state, action) => {
      return {
        ...state,
        loadedGroupList: false,
        loadingGroupList: true
      };
    }),
    on(GroupActions.updateGroupListIds, (state, action) => {
      const currentList = [...state.groupList];
      return {
        ...state,
        groupList: [...currentList, action.id]
      };
    }),
    on(GroupActions.upsertGroupListSuccess,
      (state, action) => adapter.upsertMany(action.groups,
        {
          ...state,
          loadedGroupList: true,
          loadingGroupList: false,
          groupList: action.ids,
          currentPageGroupList: action.currentPage,
          totalGroups: action.total
        })
    ),
    on(GroupActions.upsertPublicGroups, (state, action) => {
      return {
        ...state,
        loadedPublicGroups: false,
        loadingPublicGroups: true
      };
    }),
    on(GroupActions.upsertPublicGroupsSuccess,
      (state, action) => adapter.upsertMany(action.groups,
        {
          ...state,
          loadedPublicGroups: true,
          loadingPublicGroups: false,
          publicGroups: action.ids,
          currentPublicGroupPage: action.currentPage,
          totalPublicGroups: action.total
        })
    ),
    on(GroupActions.upsertPrivateGroups, (state, action) => {
      return {
        ...state,
        loadedPrivateGroups: false,
        loadingPrivateGroups: true
      };
    }),
    on(GroupActions.upsertPrivateGroupsSuccess,
      (state, action) => adapter.upsertMany(action.groups,
        {
          ...state,
          loadedPrivateGroups: true,
          loadingPrivateGroups: false,
          privateGroups: action.ids,
          currentPrivateGroupPage: action.currentPage,
          totalPrivateGroups: action.total
        })
    ),
  on(GroupActions.upsertPrivateGroupsFailed,
    (state, action) => {
      return {
        ...state,
        loadedPrivateGroups: false,
        loadingPrivateGroups: false
      };
    }
    ),

    on(GroupActions.updateGroup,
      (state, action) =>
        adapter.updateOne({
          id: action.id,
          changes: { ...action.changes }
        }, state)
    ),
    on(GroupActions.addGroupPost,
      (state, action) => {
        const selectedEntity = state.entities[action.id];
        const posts=selectedEntity?.posts??[]
       return  adapter.updateOne({
          id: action.id,
          changes: { posts:[action.post,...posts] }
        }, state);
      }
    ),
    on(GroupActions.updateGroups,
      (state, action) => adapter.updateMany(action.groups, state)
    ),
    on(GroupActions.deleteGroup,
      (state, action) => adapter.removeOne(action.id, state)
    ),
    on(GroupActions.deleteGroups,
      (state, action) => adapter.removeMany(action.ids, state)
    ),
    on(GroupActions.loadGroups,
      (state, action) => adapter.setAll(action.groups, state)
    ),
    on(GroupActions.clearGroups,
      state => adapter.removeAll(state)
    )
  )
;

export const SelectGroupFeature = (state: AppState) => state[groupsFeatureKey];
export const SelectGroupsByIds = (ids: string[]) => createSelector(
  selectAll,
  (entities: Group[]) => entities.filter(g => ids?.includes(g._id))
);
export const SelectGroupsByNames = (ids: string[]) => createSelector(
  selectAll,
  (entities: Group[]) => entities.filter(g => ids?.includes(g.uniqueGroupName))
);
export const SelectGroup = (name: string) => createSelector(
  selectEntities,
  (entities: any) => entities[name]
);

export const SelectGroupListData = (state: AppState) => {
  return {
    ids: state[groupsFeatureKey].groupList,
    load: state[groupsFeatureKey].loadedGroupList,
    loading: state[groupsFeatureKey].loadingGroupList,
    currentPage: state[groupsFeatureKey].currentPageGroupList,
    total: state[groupsFeatureKey].totalGroups
  };
};
export const SelectPublicGroupsData = (state: AppState) => {
  return {
    ids: state[groupsFeatureKey].publicGroups,
    load: state[groupsFeatureKey].loadedPublicGroups,
    loading: state[groupsFeatureKey].loadingPublicGroups,
    currentPage: state[groupsFeatureKey].currentPublicGroupPage,
    total: state[groupsFeatureKey].totalPublicGroups
  };
};
export const SelectPrivateGroupsData = (state: AppState) => {
  return {
    ids: state[groupsFeatureKey].privateGroups,
    load: state[groupsFeatureKey].loadedPrivateGroups,
    loading: state[groupsFeatureKey].loadingPrivateGroups,
    currentPage: state[groupsFeatureKey].currentPrivateGroupPage,
    total: state[groupsFeatureKey].totalPrivateGroups
  };
};
export const SelectGroupByName = (name: string) => createSelector(
  selectAll,
  (entities: Group[]) =>
    entities.find(g => g.uniqueGroupName === name)
);
export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors(SelectGroupFeature);
