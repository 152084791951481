import { createAction, createActionGroup, emptyProps, props } from "@ngrx/store";
import { Update } from '@ngrx/entity';

import { GroupRequest } from './group-request.model';

export const loadGroupRequests = createAction(
  '[GroupRequest/API] Load GroupRequests',
  props<{ groupRequests: GroupRequest[] }>()
);

export const addGroupRequest = createAction(
  '[GroupRequest/API] Add GroupRequest',
  props<{ groupRequest: GroupRequest }>()
);

export const upsertGroupRequest = createAction(
  '[GroupRequest/API] Upsert GroupRequest',
  props<{ groupRequest: GroupRequest }>()
);

export const addGroupRequests = createAction(
  '[GroupRequest/API] Add GroupRequests',
  props<{ groupRequests: GroupRequest[] }>()
);

export const upsertGroupRequests = createAction(
  '[GroupRequest/API] Upsert GroupRequests',
  props<{ groupRequests: GroupRequest[] }>()
);

export const upsertGroupRequestsSuccess = createAction(
  '[GroupRequest/API] Upsert GroupRequests Success',
  props<{ groupRequests: GroupRequest[] }>()
);

export const updateGroupRequest = createAction(
  '[GroupRequest/API] Update GroupRequest',
  props<{ groupRequest: Update<GroupRequest> }>()
);

export const updateGroupRequests = createAction(
  '[GroupRequest/API] Update GroupRequests',
  props<{ groupRequests: Update<GroupRequest>[] }>()
);

export const deleteGroupRequest = createAction(
  '[GroupRequest/API] Delete GroupRequest',
  props<{ id: string }>()
);

export const deleteGroupRequests = createAction(
  '[GroupRequest/API] Delete GroupRequests',
  props<{ ids: string[] }>()
);

export const clearGroupRequests = createAction(
  '[GroupRequest/API] Clear GroupRequests');

