import { createAction, createActionGroup, emptyProps, props } from "@ngrx/store";
import { Update } from "@ngrx/entity";

import { UserProfile } from "./user-profile.model";

export const loadUserProfiles = createAction(
  "[UserProfile/API] Load UserProfiles",
  props<{ userProfiles: UserProfile[] }>()
);
export const loadUserPostsData = createAction(
  "[Userprofile/API] Load User Posts Data",
  props<{ totalPosts:number,currentPostPage:number,id:string,postType:string }>()
);

export const loadUserTagsData = createAction(
  "[Userprofile/API] Load User Tags Data",
  props<{ totalTags:number,currentTagPage:number,id:string }>()
);

export const loadUserFollowersData = createAction(
  "[Userprofile/API] Load User Followers Data",
  props<{ totalFollowers:number,currentFollowPage:number,id:string,followType:string }>()
);

export const upsertPostsByUser = createAction(
  "[Userprofile/API] Upsert Posts By User",
  props<{_id:string, userName: string, page: number, limit: number,
  postType:string}>()
);

export const upsertUserGroupRequest = createAction(
  "[Userprofile/API] Upsert User Group Request",
  props<{id:string, page: number, limit: number}>()
);

export const loadUserGroupRequestData = createAction(
  "[Userprofile/API] Load User Group Request Data",
  props<{id:string, totalRequests:number,currentGroupRequestPage:number,requests:string[] }>()
);

export const upsertTagsByUser = createAction(
  "[Userprofile/API] Upsert Tags By User",
  props<{_id:string, userName: string, page: number, limit: number }>()
);

export const upsertFollowersByUser = createAction(
  "[Userprofile/API] Upsert Followers By User",
  props<{_id:string, userName: string, page: number, limit: number,followType:string }>()
);

export const addUserProfile = createAction(
  "[UserProfile/API] Add UserProfile",
  props<{ userProfile: UserProfile }>()
);

export const upsertUserProfile = createAction(
  "[UserProfile/API] Upsert UserProfile",
  props<{ userProfile: UserProfile }>()
);
export const upsertUserProfilesSucsess = createAction(
  "[UserProfile/API] Upsert UserProfiles Sucsess",
  props<{ userProfiles: UserProfile[] }>()
);

export const upsertPublicUserProfile = createAction(
  "[UserProfile/API] Upsert Public UserProfile",
  props<{ userProfileName:string }>()
)

;export const upsertPublicUserProfileSucsess = createAction(
  "[UserProfile/API] Upsert Public UserProfile Sucsess",
  props<{ userProfile: UserProfile }>()
);

export const upsertTagFollowersSucsess = createAction(
  "[UserProfile/API] Upsert Tag Followers Sucsess",
  props<{ userProfiles: UserProfile[] }>()
);


export const addUserProfiles = createAction(
  "[UserProfile/API] Add UserProfiles",
  props<{ userProfiles: UserProfile[] }>()
);

export const upsertBusinessUserProfiles = createAction(
  "[UserProfile/API] Upsert Business UserProfiles",
  props<{page: number, limit: number, globalSearch: string,selectType:string }>()
);

export const upsertBusinessUserProfilesSuccess = createAction(
  "[UserProfile/API] Upsert Business UserProfiles Success",
  props<{ userProfiles: UserProfile[],usersIds:string[],total:number,currentPage:number }>()
);

export const upsertRegularUserProfiles = createAction(
  "[UserProfile/API] Upsert Regular UserProfiles",
  props<{page: number, limit: number, globalSearch: string,selectType:string }>()
);

export const upsertSearchResultUserProfiles = createAction(
  "[UserProfile/API] Upsert Search Result UserProfiles",
  props<{page: number, limit: number, globalSearch: any,selectType:string }>()
);

export const upsertSearchResultUserProfilesSucsess = createAction(
  "[UserProfile/API] Upsert Search Result UserProfiles Sucsess",
  props<{userProfiles: UserProfile[],total:number,currentPage:number }>()
);

export const upsertRegularUserProfilesSuccess = createAction(
  "[UserProfile/API] Upsert Regular UserProfiles Success",
  props<{ userProfiles: UserProfile[],usersIds:string[],total:number,currentPage:number }>()
);

export const updateUserProfile = createAction(
  "[UserProfile/API] Update UserProfile",
  props<{ id: string, changes: any }>()
);

export const updateUserProfilesSucsess = createAction(
  "[UserProfile/API] Update UserProfiles Sucsess",
  props<{ userProfiles: Update<UserProfile>[] }>()
);

export const deleteUserProfile = createAction(
  "[UserProfile/API] Delete UserProfile",
  props<{ id: string }>()
);

export const deleteUserProfiles = createAction(
  "[UserProfile/API] Delete UserProfiles",
  props<{ ids: string[] }>()
);

export const clearUserProfiles = createAction(
  "[UserProfile/API] Clear UserProfiles"
);
