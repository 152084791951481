import { createFeature, createReducer, createSelector, on } from "@ngrx/store";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { UserProfile } from "./user-profile.model";
import * as UserProfileActions from "./user-profile.actions";
import { AppState } from "../../reducers";
import { Tag } from "../tag/tag.model";
import { upsertUserProfilesSucsess } from "./user-profile.actions";
import * as GeneralStateActions from "../general-state/general-state.actions";


export const userProfilesFeatureKey = "userProfiles";

export interface State extends EntityState<UserProfile> {
  // additional entities state properties
  businessUsers: string[],
  loadedBusinessUsers: boolean,
  loadingBusinessUsers: boolean,
  currentPageBusiness: number;
  totalBusiness: number;

  regularUsers: string[],
  loadedRegularUsers: boolean,
  loadingRegularUsers: boolean,
  currentPageRegular: number,
  totalRegular: number,

  currentPageSearchResult: number,
  totalSearchResult: number,
}

export const adapter: EntityAdapter<UserProfile> = createEntityAdapter<UserProfile>({
  selectId: (u: UserProfile) => u._id
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  businessUsers: [],
  loadedBusinessUsers: false,
  loadingBusinessUsers: false,
  currentPageBusiness: 0,
  totalBusiness: 0,

  regularUsers: [],
  loadedRegularUsers: false,
  loadingRegularUsers: false,
  currentPageRegular: 0,
  totalRegular: 0,

  currentPageSearchResult: 0,
  totalSearchResult: 0,
});

export const reducer = createReducer(
  initialState,
  on(UserProfileActions.addUserProfile,
    (state, action) => adapter.addOne(action.userProfile, state)
  ),
  on(UserProfileActions.upsertUserProfile,
    (state, action) => adapter.upsertOne(action.userProfile, state)
  ),

  on(UserProfileActions.upsertPublicUserProfileSucsess,
    (state, action) => adapter.upsertOne(action.userProfile, state)
  ),
  on(UserProfileActions.upsertUserProfilesSucsess,
    (state, action) => adapter.upsertMany(action.userProfiles, state)
  ),
  on(UserProfileActions.addUserProfiles,
    (state, action) => adapter.addMany(action.userProfiles, state)
  ),

  on(UserProfileActions.upsertBusinessUserProfiles, (state, action) => {
    return {
      ...state,
      loadedBusinessUsers: false,
      loadingBusinessUsers: true
    };
  }),
  on(UserProfileActions.upsertBusinessUserProfilesSuccess,
    (state, action) =>{
   // console.log(action)
    return adapter.upsertMany(action.userProfiles,
      {
        ...state,
        loadedBusinessUsers: true,
        loadingBusinessUsers: false,
        businessUsers: action.usersIds,
        currentPageBusiness: action.currentPage,
        totalBusiness: action.total

      })}
  ),

  on(UserProfileActions.upsertRegularUserProfiles, (state, action) => {
    return {
      ...state,
      loadedRegularUsers: false,
      loadingRegularUsers: true
    };
  }),
  on(UserProfileActions.upsertRegularUserProfilesSuccess,
    (state, action) => adapter.upsertMany(action.userProfiles,
      {
        ...state,
        loadedRegularUsers: true,
        loadingRegularUsers: false,
        regularUsers: action.usersIds,
        currentPageRegular: action.currentPage,
        totalRegular: action.total

      })
  ),
  on(UserProfileActions.upsertSearchResultUserProfilesSucsess,
    (state, action) => adapter.upsertMany(action.userProfiles,
      {
        ...state,
        currentPageSearchResult: action.currentPage,
        totalSearchResult: action.total,
      })
  ),
  on(UserProfileActions.upsertTagFollowersSucsess,
    (state, action) => adapter.upsertMany(action.userProfiles, state)
  ),

  on(UserProfileActions.updateUserProfile,
    (state, action) => adapter.updateOne({
      id: action.id,
      changes: { ...action.changes }
    }, state)
  ),
  on(UserProfileActions.updateUserProfilesSucsess,
    (state, action) => adapter.updateMany(action.userProfiles, state)
  ),
  on(UserProfileActions.deleteUserProfile,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(UserProfileActions.deleteUserProfiles,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(UserProfileActions.loadUserProfiles,
    (state, action) => adapter.setAll(action.userProfiles, state)
  ),

  on(UserProfileActions.loadUserPostsData,
    (state, action) => {
    let changes:any={
      totalPosts: action.totalPosts,
      currentPostPage: action.currentPostPage,
      loadedPosts: true,
      loadingPosts: false
    }
    if(action.postType==='liked')
    {
      changes={
        totalLikedPosts: action.totalPosts,
        currentLikedPostPage: action.currentPostPage,
        loadedLikedPosts: true,
        loadingLikedPosts: false
      }
    }
    console.log(changes)
      return adapter.updateOne({
        id: action.id,
        changes: {
          // totalPosts: action.totalPosts,
          // currentPostPage: action.currentPostPage,
          // loadedPosts: true,
          // loadingPosts: false
          ...changes
        }
      }, state);
    }
  ),

  on(UserProfileActions.loadUserTagsData,
    (state, action) => {
      return adapter.updateOne({
        id: action.id,
        changes: {
          totalTags: action.totalTags, currentTagPage:
          action.currentTagPage, loadedTags: true, loadingTags: false
        }
      }, state);
    }
  ),
  on(UserProfileActions.loadUserGroupRequestData,
    (state, action) => {
      return adapter.updateOne({
        id: action.id,
        changes: {
          totalGroupRequests: action.totalRequests,
          currentGroupRequestPage:action.currentGroupRequestPage,
          groupRequests:action.requests
        }
      }, state);
    }
  ),
  on(UserProfileActions.loadUserFollowersData,
    (state, action) => {
      let changes = {};
      if (action.followType === "me") {
        changes = {
          loadedFollowers: true,
          loadingFollowers: false,
          totalFollowers: action.totalFollowers,
          currentFollowPage: action.currentFollowPage
        };
      } else {
        changes = {
          loadedIFollow: true,
          loadingIFollower: false,
          totalIFollow: action.totalFollowers,
          currentIFollowPage: action.currentFollowPage
        };
      }
      return adapter.updateOne({

        id: action.id,
        changes: {
          ...changes
        }
      }, state);
    }
  ),


  on(UserProfileActions.upsertFollowersByUser,
    (state, action) => {
      let changes = {};
      if (action.followType === "me") {
        changes = {
          loadedFollowers: false,
          loadingFollowers: true
        };
      } else {
        changes = {
          loadedIFollow: false,
          loadingIFollower: true
        };
      }
      return adapter.updateOne({
        id: action._id,
        changes: {
          ...changes
        }
      }, state);
    }
  ),

  on(UserProfileActions.upsertPostsByUser,
    (state, action) => {
    let changes:any={
      loadedPosts: false,
      loadingPosts: true
    }
    if(action.postType==='liked')
    {
      changes={
        loadedLikedPosts: false,
        loadingLikedPosts: true
      }
    }
      return adapter.updateOne({
        id: action._id,
        changes: {
          // loadedPosts: false,
          // loadingPosts: true
          ...changes
        }
      }, state);
    }
  ),

  on(UserProfileActions.upsertTagsByUser,
    (state, action) => {
      return adapter.updateOne({
        id: action._id,
        changes: { loadedTags: false, loadingTags: true }
      }, state);
    }
  ),
  on(UserProfileActions.clearUserProfiles,
    state => adapter.removeAll(state)
  )
);

export const SelectUserProfileFeature = (state: AppState) => state[userProfilesFeatureKey];

export const SelectUserProfileByIds = (ids: string[]) => createSelector(
  selectAll,
  (entities: UserProfile[]) => entities.filter(u => ids?.includes(u._id))
);

export const SelectUserProfileById = (id: string) => createSelector(
  selectEntities,
  (entities: any) => entities[id]
);

export const SelectUserProfileByUserName = (userName: string) => createSelector(
  selectAll,
  (entities: UserProfile[]) =>
    entities.find(u => u.userName === userName)
);

export const SelectBusinessUsersData = (state: AppState) => {
  return {
    ids: state[userProfilesFeatureKey].businessUsers,
    load: state[userProfilesFeatureKey].loadedBusinessUsers,
    loading: state[userProfilesFeatureKey].loadingBusinessUsers,
    currentPage: state[userProfilesFeatureKey].currentPageBusiness,
    total: state[userProfilesFeatureKey].totalBusiness
  };
};
export const SelectRegularUsersData = (state: AppState) => {
  return {
    ids: state[userProfilesFeatureKey].regularUsers,
    load: state[userProfilesFeatureKey].loadedRegularUsers,
    loading: state[userProfilesFeatureKey].loadingRegularUsers,
    currentPage: state[userProfilesFeatureKey].currentPageRegular,
    total: state[userProfilesFeatureKey].totalRegular
  };
};
export const SelectSearchResultUsersData = (state: AppState) => {
  return {
    currentPage: state[userProfilesFeatureKey].currentPageSearchResult,
    total: state[userProfilesFeatureKey].totalSearchResult
  };
};
export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors(SelectUserProfileFeature);
