import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  loadGroupMembersData,
  loadGroupRequestsData,
  upsertGroupMembers,
  upsertGroupRequests
} from "../group/group.actions";
import { catchError, mergeMap, switchMap } from "rxjs/operators";
import { upsertUserProfilesSucsess } from "../user-profile/user-profile.actions";
import { GroupService } from "../../pages/group/group.service";
import { upsertGroupRequestsSuccess } from "./group-request.actions";



@Injectable()
export class GroupRequestEffects {
  loadGroupRequests$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(upsertGroupRequests),
      switchMap((action) =>
        this.groupService.getGroupRequests(action.name, action.page, action.limit).pipe(
          mergeMap((res: any) => {
            // console.log(res.users)
            return [upsertGroupRequestsSuccess({ groupRequests: res.requests }),
              loadGroupRequestsData({
                totalRequests: res.totalRequests,
                currentRequestPage: res.currentPage,
                name: action.name
              })];
          }),
          catchError(e => {
            return [];
          })
        )
      )
    );
  });

  constructor(private actions$: Actions,private groupService:GroupService) {}
}
