import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { upsertTag, upsertTagSucsess } from "../tag/tag.actions";
import { catchError, map, switchMap } from "rxjs/operators";
import {
  upsertGroup,
  upsertGroupFailed,
  upsertGroupList, upsertGroupListSuccess,
  upsertGroupSucsess, upsertPrivateGroups, upsertPrivateGroupsFailed, upsertPrivateGroupsSuccess
} from "./group.actions";
import { GroupService } from "../../pages/group/group.service";
import { upsertPublicGroups } from "./group.actions";
import { upsertPublicGroupsSuccess } from "./group.actions";
import { Router } from "@angular/router";



@Injectable()
export class GroupEffects {

  upsertGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(upsertGroup),
      switchMap((action) =>
        this.groupService.getGroupData(action.name).pipe(
          map((res: any) => {
            return upsertGroupSucsess({group:res})
          }),
          catchError(e => {
            console.log(e.error)
            return [upsertGroupFailed({group:e.error})]
          })
        )
      )
    );
  });

  upsertGroupList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(upsertGroupList),
      switchMap((action) =>
        this.groupService.getUserGroupList(action.page,action.limit,action.globalSearch).pipe(
          map((res: any) => {
            return upsertGroupListSuccess({groups:res.groups,ids:res.ids,
            total:res.total,currentPage:res.currentPage})
          }),
          catchError(e => {
            // console.log(e.error)
            return []
          })
        )
      )
    );
  });

  upsertPublicGroups$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(upsertPublicGroups),
      switchMap((action) =>
        this.groupService.getGroups(action.page,action.limit,action.groupType,action.globalSearch).pipe(
          map((res: any) => {
            return upsertPublicGroupsSuccess({groups:res.groups,ids:res.ids,
            total:res.total,currentPage:res.currentPage})
          }),
          catchError(e => {
            // console.log(e.error)
            return []
          })
        )
      )
    );
  });

  upsertPrivateGroups$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(upsertPrivateGroups),
      switchMap((action) =>
        this.groupService.getGroups(action.page,action.limit,action.groupType,action.globalSearch).pipe(
          map((res: any) => {
            //console.log(res)
            return upsertPrivateGroupsSuccess({groups:res.groups,ids:res.ids,
            total:res.total,currentPage:res.currentPage})
          }),
          catchError(e => {
             console.log(e.error)
            if(e.error.statusCode===4004)
            {
              this.router.navigate(["/auth/login"] );
            }
            return [upsertPrivateGroupsFailed()]
          })
        )
      )
    );
  });

  constructor(private actions$: Actions,private groupService:GroupService,
              private router: Router,) {}
}
