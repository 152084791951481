import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
// language list
import { locale as enLang } from './modules/i18n/vocabs/en';
import { locale as chLang } from './modules/i18n/vocabs/ch';
import { locale as esLang } from './modules/i18n/vocabs/es';
import { locale as jpLang } from './modules/i18n/vocabs/jp';
import { locale as deLang } from './modules/i18n/vocabs/de';
import { locale as frLang } from './modules/i18n/vocabs/fr';
import { ThemeModeService } from './_metronic/partials/layout/theme-mode-switcher/theme-mode.service';
import { TranslateService } from "@ngx-translate/core";
import { Store } from "@ngrx/store";
import { AppState } from "./reducers";
import { AuthService } from "./modules/auth";
import { AuthHTTPService } from "./modules/auth/services/auth-http/auth-http.service";
import { SetUserAccountData } from "./state/general-state/general-state.actions";
import Swal from "sweetalert2";

@Component({
  // tslint:disable-next-line:component-selector
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
  //adding in angular.json script array
export class AppComponent implements OnInit {
  constructor(
    // private translationService: TranslateService,
    private modeService: ThemeModeService,
    translate: TranslateService,
    private store: Store<AppState>,
    private authService: AuthService,
    private authHTTPService: AuthHTTPService,
  ) {

    // register translations
    // this.translationService.loadTranslations(
    //   enLang,
    //   chLang,
    //   esLang,
    //   jpLang,
    //   deLang,
    //   frLang
    // );
    translate.setDefaultLang('he');
    translate.use('he');
  }
  user: any;
  ngOnInit() {
    // Swal.fire({
    //   toast: true,
    //   position: "top-right",
    //   showConfirmButton: false,
    //   //timer: 3000,
    //   // title: "Success!",
    //   text:'fsdf' ,
    //   icon: "success"
    // });
    this.modeService.init();
    this.user = this.authService.getUserValue;
    if (this.user) {
      this.authHTTPService.getUserProfile().subscribe((res: any) => {
        this.store.dispatch(SetUserAccountData({
          data: { ...res.data }
        }));
      });
    }
  }
}
