import { createReducer, createSelector, on } from "@ngrx/store";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { Post } from "./post.model";
import * as PostActions from "./post.actions";
import { AppState } from "../../reducers";
import { userProfilesFeatureKey } from "../user-profile/user-profile.reducer";
import * as UserProfileActions from "../user-profile/user-profile.actions";


export const postsFeatureKey = "posts";

export interface State extends EntityState<Post> {
  // additional entities state properties

  totalSearchResultPost: number,
  currentSearchResultPage: number
}

export const adapter: EntityAdapter<Post> = createEntityAdapter<Post>({
  selectId: (p: Post) => p._id
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  totalSearchResultPost: 0,
  currentSearchResultPage: 0
});

export const reducer = createReducer(
  initialState,
  on(PostActions.addPost,
    (state, action) => adapter.addOne(action.post, state)
  ),
  on(PostActions.upsertPostSucsess,
    (state, action) => adapter.upsertOne(action.post, state)
  ),  on(PostActions.upsertPostFailed,
    (state, action) => adapter.upsertOne(action.post, state)
  ),
  on(PostActions.upsertSearchResultPostsSucsess,
    (state, action) => adapter.upsertMany(action.posts,
      {
        ...state,
        totalSearchResultPost: action.totalPosts,
        currentSearchResultPage: action.currentPage
      })
  ),

  on(PostActions.updatePostSocialDataReaction,
    (state, action) => {
      const selectedEntity = state.entities[action.id];
      return adapter.updateOne({
        id: action.id,
        changes: {
          socialPostData: { ...selectedEntity?.socialPostData, ...action.changes }
        }
      }, state);
    }
  ),
  on(PostActions.UpdatePostCommentData,
    (state, action) => adapter.updateOne({
      id: action.id,
      changes: { ...action.changes, loadingComment: false, loadedComment: true }
    }, state)
  ),
  on(PostActions.updatePostSocialDataComment,
    (state, action) => {
      const selectedEntity = state.entities[action.id];
      return adapter.updateOne({
        id: action.id,
        changes: {
          socialPostData: {
            ...selectedEntity?.socialPostData,
            ...action.changes
          }
        }
      }, state);
    }
  ),
  on(PostActions.upsertPostComments,
    (state, action) => {
      return adapter.updateOne({
        id: action.post,
        changes: { loadingComment: true, loadedComment: false }
      }, state);
    }
  ),

  on(PostActions.updatePostUserProfile,
    (state, action) => {
      const selectedEntity = state.entities[action.id];
      return adapter.updateOne({
        id: action.id,
        changes: {
          userProfile: {
            ...selectedEntity?.userProfile,
            ...action.changes
          }
        }
      }, state);
    }
  ),
  on(PostActions.addPosts,
    (state, action) => adapter.addMany(action.posts, state)
  ),
  on(PostActions.upsertPostsSucsess,
    (state, action) => adapter.upsertMany(action.posts, state)
  ),

  on(PostActions.upsertPostByTagSucsess,
    (state, action) => adapter.upsertMany(action.posts, state)
  ),
  on(PostActions.upsertPostsByGroupSucsess,
    (state, action) => adapter.upsertMany(action.posts, state)
  ),
  on(PostActions.updatePost,
    (state, action) => {
      return adapter.updateOne({
        id: action.id,
        changes: { ...action.changes }
      }, state);
    }
  ),
  on(PostActions.updatePosts,
    (state, action) => adapter.updateMany(action.posts, state)
  ),
  on(PostActions.deletePost,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(PostActions.deletePosts,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(PostActions.loadPosts,
    (state, action) => adapter.setAll(action.posts, state)
  ),
  on(PostActions.clearPosts,
    state => adapter.removeAll(state)
  )
);
export const SelectPostFeature = (state: AppState) => state[postsFeatureKey];
export const SelectSearchResultPostsData = (state: AppState) => {
  return {
    currentPage: state[postsFeatureKey].currentSearchResultPage,
    total: state[postsFeatureKey].totalSearchResultPost
  };
};

export const SelectPostsIds = (ids: string[]) => createSelector(
  selectAll,
  (entities: Post[]) => {
    //console.log('ids',ids)
    return entities.filter(g => ids?.includes(g._id))
      //    .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
  });
export const SelectPostsIdsSortByUpdated = (ids: string[]) => createSelector(
  selectAll,
  (entities: Post[]) => {
    //console.log('ids',ids)
    return entities.filter(g => ids?.includes(g._id))
         .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
  });
export const SelectLikedPostsIds = (ids: string[]) => createSelector(
  selectAll,
  (entities: Post[]) => {
    //console.log('ids',ids)
    return entities.filter(g => ids?.includes(g._id))
      .sort((a, b) => b.socialPostData.reactions.length - a.socialPostData.reactions.length);
  });

export const SelectFeedPosts = (ids: string[]) => createSelector(
  selectAll,
  (entities: Post[]) => {
    // console.log('ids',ids)
    let regularPosts = entities.filter(item => ids.includes(item._id) &&
      !item.prioritized).sort((a, b) => new Date(b.updatedAt).getTime() -
      new Date(a.updatedAt).getTime());
    const prioritized = entities.filter(p => p.prioritized).sort((a, b) => new Date(b.updatedAt).getTime() -
      new Date(a.updatedAt).getTime());
    //  console.log(foundIndexPrioritized)
    const result = [];
    let arr2Index = 0;

    for (let i = 0; i < regularPosts.length; i++) {
      result.push(regularPosts[i]);

      // After every 4 elements in arr1, push one element from arr2
      if ((i + 1) % 4 === 0 && arr2Index < prioritized.length) {
        result.push(prioritized[arr2Index]);
        arr2Index++;
      }
    }
    //console.log(result)
    return result;
    //  prioritized.forEach((prioritizedPost: any, index: number) => {
    //    filteredItems.splice(((index+1)*5), 0, prioritizedPost);
    //  })
    //  console.log(filteredItems)
    //  return filteredItems
    //
    // // Sort the regular posts by the last updated time (descending order)
    // const sortedRegularPosts = filteredItems
    //   .filter(item => !item.prioritized)
    //   .sort((a, b) => new Date(b.updatedAt).getTime() -
    //     new Date(a.updatedAt).getTime());
    //
    // // Filter prioritized posts
    // const prioritizedPosts = filteredItems.filter(item => item.prioritized);
    //
    // // Concatenate sorted regular posts with prioritized posts at the end
    // return [...sortedRegularPosts, ...prioritizedPosts];
  });

export const SelectPostById = (id: string) => createSelector(
  selectEntities,
  (entities: any) => entities[id]
);export const SelectPostOfGroupByIdLayout1 = (id: string) => createSelector(
  selectEntities,
  (entities: any) => entities[id]?? {  }
);
export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors(SelectPostFeature);
