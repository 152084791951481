import { createAction, createActionGroup, emptyProps, props } from "@ngrx/store";
import { Update } from "@ngrx/entity";
import { Post } from "./post.model";


export const upsertSearchResultPosts = createAction(
  "[Post/API] Upsert Search Result Posts",
  props<{ page: number, limit: number, globalSearch: string }>()
);
export const upsertSearchResultPostsSucsess = createAction(
  "[Post/API] Upsert Search Result Posts Sucsess",
  props<{ posts: Post[], totalPosts: number, currentPage: number }>()
);

export const loadPosts = createAction(
  "[Post/API] Load Posts",
  props<{ posts: Post[] }>()
);

export const addPost = createAction(
  "[Post/API] Add Post",
  props<{ post: Post }>()
);

export const upsertPost = createAction(
  "[Post/API] Upsert Post",
  props<{ post: string }>()
);

export const upsertPostSucsess = createAction(
  "[Post/API] Upsert Post Sucsess",
  props<{ post: Post }>()
);
export const upsertPostFailed = createAction(
  "[Post/API] Upsert Post Failed",
  props<{ post: Post }>()
);


export const upsertMainFeedPostsSucsess = createAction(
  "[Post/API] Upsert Main Feed Posts Sucsess",
  props<{ posts: Post[] }>()
);

export const upsertPostsByGroupSucsess = createAction(
  "[Post/API] Upsert Posts By Group Sucsess",
  props<{ posts: Post[] }>()
);

export const upsertPostByTagSucsess = createAction(
  "[Post/API] Upsert Posts By Tag Sucsess",
  props<{ posts: Post[] }>()
);

export const addPosts = createAction(
  "[Post/API] Add Posts",
  props<{ posts: Post[] }>()
);

export const updatePostSocialDataReaction = createAction(
  "[Post/API] Update Post Social Data Reaction",
  props<{ id: string, changes: any }>()
);

export const upsertPostComments = createAction(
  "[Post/API] Upsert PostComments",
  props<{ post: string, page: number, limit: number }>()
);

export const UpdatePostCommentData = createAction(
  "[Post/API] Update Post Comment Data",
  props<{ id: string, changes: any }>()
);
export const updatePostSocialDataComment = createAction(
  "[Post/API] Update Post Social Data comment",
  props<{ id: string, changes: any }>()
);

export const updatePostUserProfile = createAction(
  "[Post/API] Update Post Social Data comment",
  props<{ id: string, changes: any }>()
);

export const upsertPostsSucsess = createAction(
  "[Post/API] Upsert Posts Sucsess",
  props<{ posts: Post[] }>()
);

export const loadPostValues = createAction(
  "[Post/API] Load Post Values",
  props<{ postSlug: string, pageSlug: string }>()
);
export const loadPostValuesSuccess = createAction(
  "[Post/API] Load Post Values Success",
  props<{ values: any[], slug: string }>()
);

export const updatePost = createAction(
  "[Post/API] Update Post",
  props<{ id:string,changes:any }>()
);

export const updatePosts = createAction(
  "[Post/API] Update Posts",
  props<{ posts: Update<Post>[] }>()
);

export const deletePost = createAction(
  "[Post/API] Delete Post",
  props<{ id: string }>()
);

export const deletePosts = createAction(
  "[Post/API] Delete Posts",
  props<{ ids: string[] }>()
);

export const clearPosts = createAction(
  "[Post/API] Clear Posts"
);
