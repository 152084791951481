import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import {
  getFlags,
  getFlagsSucsess,
  getLastUpdatedData,
  getLastUpdatedDataSucsess,
  loadNotificationSettings,
  loadNotificationSettingsSucsess,
  loadSearchNav,
  loadSearchNavSucsess,
  loadUserStores,
  loadUserStoresSucsess,
  upsertFeedLayout1,
  upsertFeedLayout1Sucsess,
  upsertFeedLayout2, upsertFeedLayout2Sucsess
} from "./general-state.actions";
import { GlobalSearchService } from "../../pages/global-search/global-search.service";
import { GeneralService } from "../../general.service";
import { upsertTagsSucsess, upsertTagSucsess } from "../tag/tag.actions";
import { addUserProfiles, upsertUserProfilesSucsess } from "../user-profile/user-profile.actions";
import { PostService } from "../../pages/post/post.service";
import { StoreService } from "../../pages/salez-profile/profile-setting/business-profile/store.service";
import { ProfileSettingService } from "../../pages/salez-profile/profile-setting/profile-setting.service";
import { getComments } from "../post/post.effects";
import { upsertPostsSucsess } from "../post/post.actions";
import { upsertPostCommentsSuccess } from "../post-comment/post-comment.actions";

// import { GeneralStateActions } from './general-state.actions';

@Injectable()
export class GeneralStateEffects {


  // nGeneralStates$ = createEffect(() => {
  //   return this.actions$.pipe(
  //
  //     ofType(GeneralStateActions.nGeneralStates),
  //     /** An EMPTY observable only emits completion. Replace with your own observable API request */
  //     concatMap(() => EMPTY as Observable<{ type: string }>)
  //   );
  // });

  loadSearchNav$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadSearchNav),
      switchMap((action) =>
        this.globalSearchService.getSearchNav(action.search).pipe(
          map((res: any) => {
            //console.log(res)
            return loadSearchNavSucsess({ nav: res });
          }),
          catchError(e => {
            return [];
          })
        )
      )
    );
  });

  loadNotificationSetting$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadNotificationSettings),
      switchMap((action) =>
        this.profileSettingService.getNotificationSettings().pipe(
          map((res: any) => {
            //console.log(res)
            return loadNotificationSettingsSucsess({
              newBusinessPostNot: res.newBusinessPostNot,
              newFriendsPostNot: res.newFriendsPostNot,
              newPostInGroupNot: res.newPostInGroupNot,
              newPostInTagNot: res.newPostInTagNot,
              followNot: res.followNot,
              joinGroupNot: res.joinGroupNot
            });
          }),
          catchError(e => {
            return [];
          })
        )
      )
    );
  });

  getLastUpdatedData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getLastUpdatedData),
      switchMap((action) =>
        this.generalService.getLastUpdatedData().pipe(
          mergeMap((res: any) => {
            return [getLastUpdatedDataSucsess({
              businessUsers: res.businessUsers.map((b: any) => b._id),
              regularUsers: res.regularUsers.map((b: any) => b._id),
              tags: res.tags.map((b: any) => b._id)
            }),
              upsertTagsSucsess({ tags: res.tags }), upsertUserProfilesSucsess({ userProfiles: [...res.businessUsers, ...res.regularUsers] })];
          }),
          catchError(e => {
            return [];
          })
        )
      )
    );
  });

  getFlags$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getFlags),
      switchMap((action) =>
        this.postService.getFlags(action.flagOf).pipe(
          mergeMap((res: any) => {
            return [getFlagsSucsess({ flagOf: action.flagOf, flags: res })];
          }),
          catchError(e => {
            return [];
          })
        )
      )
    );
  });

  loaduserStores$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadUserStores),
      switchMap((action) =>
        this.storeService.getStores().pipe(
          mergeMap((res: any) => {
            return [loadUserStoresSucsess({ stores: res })];
          }),
          catchError(e => {
            return [];
          })
        )
      )
    );
  });

  upsertFeedLayout1$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(upsertFeedLayout1),
      switchMap((action) =>
        this.postService.getFeedLayout1(action.page).pipe(
          mergeMap((res: any) => {
            //console.log(res)
            let posts = [...res.hotDeals, res.prioritizedPost, res.postFromTags];
            if (res.postFromPrivateGroup) {
              posts = [...posts, res.postFromPrivateGroup];
            }
            const comments = getComments(posts
            );
            return [upsertPostsSucsess({ posts: posts }),
              upsertFeedLayout1Sucsess({

                currentPage: res.currentPage,
                currentLayout: {
                  layout:1,
                  prioritizedPost: res.prioritizedPost._id,
                  postFromTags: res.postFromTags._id,
                  postFromPrivateGroup: res.postFromPrivateGroup?._id,
                  hotDeals: res.hotDeals.map((p: any) => p._id),
                  toFollow: res.toFollow.map((t: any) => t._id)
                }
                // layout1PrioritizedPost:res.prioritizedPost._id,
                // layout1PostFromTags:res.postFromTags._id,
                // layout1PostFromPrivateGroup:res.postFromPrivateGroup?._id,
                // layout1hotDeals: res.hotDeals.map((p:any)=>p._id),
                // layout1ToFollow:res.toFollow.map((t:any)=>t._id),


              }),
              addUserProfiles({ userProfiles: res.toFollow }),
              upsertPostCommentsSuccess({ postComments: comments })];
          }),
          catchError(e => {
            console.log(e);
            return [];
          })
        )
      )
    );
  });

  upsertFeedLayout2$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(upsertFeedLayout2),
      switchMap((action) =>
        this.postService.getFeedLayout2(action.page).pipe(
          mergeMap((res: any) => {
            //console.log(res)
            let posts:any = [];
            if (res.postFromMyMember) {
              posts = [...posts, res.postFromMyMember];
            }if (res.businessFollowPosts) {
              posts = [...posts, ...res.businessFollowPosts];
            }if (res.postFromPublicGroup) {
              posts = [...posts, res.postFromPublicGroup];
            }
            const comments = getComments(posts
            );
            return [upsertPostsSucsess({ posts: posts }),
              upsertFeedLayout2Sucsess({
                currentPage: res.currentPage,
                currentLayout: {
                  layout:2,
                  postFromMyMember:res?.postFromMyMember?._id,
                  businessFollowPosts:res?.businessFollowPosts.map((b:any)=>b._id),
                  postFromPublicGroup:res?.postFromPublicGroup?._id,
                  businessToFollow: res.businessToFollow.map((t: any) => t._id)

                }
              }),
              addUserProfiles({ userProfiles: res.businessToFollow }),
              upsertPostCommentsSuccess({ postComments: comments })];
          }),
          catchError(e => {
            console.log(e);
            return [];
          })
        )
      )
    );
  });

  constructor(private actions$: Actions,
              private globalSearchService: GlobalSearchService,
              private postService: PostService,
              private storeService: StoreService,
              private profileSettingService: ProfileSettingService,
              private generalService: GeneralService) {
  }
}
