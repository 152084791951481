import { createAction, createActionGroup, emptyProps, props } from "@ngrx/store";
import { Update } from '@ngrx/entity';

import { Notification } from './notification.model';

export const loadNotifications = createAction(
  '[Notification/API] Load Notifications',
  props<{ notifications: Notification[] }>()
);

export const addNotification = createAction(
  '[Notification/API] Add Notification',
  props<{ notification: Notification }>()
);

export const upsertNotification = createAction(
  '[Notification/API] Upsert Notification',
  props<{ notification: Notification }>()
);


export const addNotifications = createAction(
  '[Notification/API] Add Notifications',
  props<{ notifications: Notification[] }>()
);

export const upsertNotifications = createAction(
  '[Notification/API] Upsert Notifications',
  props<{ page:number,limit:number }>()
);
export const upsertNotificationsSucsess = createAction(
  '[Notification/API] Upsert Notifications Sucsess',
  props<{ notifications: Notification[],currentPage:number,totalNotifications:number,allNotifications:string[] }>()
);
export const upsertNotificationsFailed = createAction(
  '[Notification/API] Upsert Notifications Failed',

);
export const upsertNotificationsSuccess = createAction(
  '[Notification/API] Upsert Notifications Success',
  props<{ notifications: Notification[] }>()
);

export const updateNotification = createAction(
  '[Notification/API] Update Notification',
  props<{ notification: Update<Notification> }>()
);

export const updateNotifications = createAction(
  '[Notification/API] Update Notifications',
  props<{ notifications: Update<Notification>[] }>()
);

export const deleteNotification = createAction(
  '[Notification/API] Delete Notification',
  props<{ id: string }>()
);

export const deleteNotifications = createAction(
  '[Notification/API] Delete Notifications',
  props<{ ids: string[] }>()
);

export const clearNotifications = createAction(
  '[Notification/API] Clear Notifications');
