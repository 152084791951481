import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  NotificationService
} from "../../_metronic/layout/components/aside/tabs/notifications-tab/notification.service";
import { loadGroupRequestsData, upsertGroupRequests } from "../group/group.actions";
import { catchError, mergeMap, switchMap } from "rxjs/operators";
import { upsertGroupRequestsSuccess } from "../group-request/group-request.actions";
import { upsertNotifications, upsertNotificationsFailed, upsertNotificationsSucsess } from "./notification.actions";
import { dateComparator } from "@ng-bootstrap/ng-bootstrap/datepicker/datepicker-tools";



@Injectable()
export class NotificationEffects {
  upsertNotifications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(upsertNotifications),
      switchMap((action) =>
        this.notificationService.getNotifications(action.page, action.limit).pipe(
          mergeMap((res: any) => {
            // console.log(res.users)
            return [upsertNotificationsSucsess({ notifications: res.notifications,currentPage:res.currentPage,
            totalNotifications:res.total.length,allNotifications:res.total.map((t:any)=>t._id)}),
             ];
          }),
          catchError(e => {
            // console.log('ERROR IN EFFECT')
            // console.log(e)
            return [upsertNotificationsFailed()];
          })
        )
      )
    );
  });

  constructor(private actions$: Actions, private notificationService:NotificationService) {}
}
