import { createReducer, on } from "@ngrx/store";
import * as GeneralStateActions from "./general-state.actions";
import { AppState } from "../../reducers";

export interface layout1 {
  layout: number,
  hotDeals: string[],
  toFollow: string[],
  prioritizedPost: string,
  postFromTags: string,
  postFromPrivateGroup: string,
}

export interface layout2 {
  layout: number,
  postFromMyMember: string,
  businessFollowPosts:string[],
  postFromPublicGroup:string,
  businessToFollow:string,
}

export const generalStateFeatureKey = "generalState";

export interface State {
  userAccount: any;
  //old feed
  // currentFeedPage: number,
  // totalFeedPosts: number,
  // feedPosts: any[],
  // loadingFeed: boolean,
  // loadedFeed: boolean,
  //old feed
  searchNav: any[] | null,
  globalSearch: string | null;
  loadedSearchNav: boolean,
  loadingSearchNav: boolean,
  lastUpdatedData: {
    businessUsers: string[],
    regularUsers: string[],
    tags: string[],
  }
  postFlags: any[],
  commentFlags: any[],
  loadedPostFlags: boolean,
  loadingPostFlags: boolean,
  loadedCommentFlags: boolean,
  loadingCommentFlags: boolean,
  loadingUserStores: boolean,
  loadedUserStores: boolean,
  userStores: any[]
  //notification settings//
  newBusinessPostNot: string[],
  newFriendsPostNot: string[],
  newPostInGroupNot: string[],
  newPostInTagNot: string[],
  followNot: string[],
  joinGroupNot: string[],
  loadedNotSetting: boolean,
  loadingNotSetting: boolean
  //notification settings//

  //new feed//

  // layout1hotDeals: string[],
  // layout1ToFollow: string[],
  // layout1PrioritizedPost: string,
  // layout1PostFromTags: string,
  // layout1PostFromPrivateGroup: string,
  // loadingLayout1: boolean,
  // loadedLayout1: boolean,
  allLayoutArray: any[],
  loadingFeed: boolean,
  loadedFeed: boolean,
  layout1currentPage: number,
  layout2currentPage: number,
  //new feed//

}

export const initialState: State = {
  userAccount: {},
  //old feed
  // currentFeedPage: 0,
  // totalFeedPosts: 0,
  // feedPosts: [],
  // loadingFeed: false,
  // loadedFeed: false,
  //old feed
  searchNav: null,
  globalSearch: null,
  loadedSearchNav: false,
  loadingSearchNav: false,
  lastUpdatedData: {
    businessUsers: [],
    regularUsers: [],
    tags: []
  },
  postFlags: [],
  commentFlags: [],
  loadedPostFlags: false,
  loadingPostFlags: false,
  loadedCommentFlags: false,
  loadingCommentFlags: false,
  loadingUserStores: false,
  loadedUserStores: false,
  userStores: [],
  //notification settings//
  newBusinessPostNot: [],
  newFriendsPostNot: [],
  newPostInGroupNot: [],
  newPostInTagNot: [],
  followNot: [],
  joinGroupNot: [],
  loadedNotSetting: false,
  loadingNotSetting: false,
  //notification settings//

  //new feed//

  // layout1PrioritizedPost:'',
  // layout1PostFromTags:'',
  // layout1PostFromPrivateGroup:'',
  // layout1hotDeals: [],
  // loadingLayout1: false,
  // loadedLayout1: false,
  // layout1ToFollow: [],
  layout1currentPage: 0,
  layout2currentPage: 0,
  allLayoutArray: [],
  loadingFeed: false,
  loadedFeed: false
  //new feed//
};

export const reducer = createReducer(
  initialState,
  // on(GeneralStateActions.nGeneralStates, state => state),
  on(GeneralStateActions.SetUserAccountData, (state, action) => {
    return {
      ...state,
      userAccount: {
        ...action.data,
        currentProfile: {
          ...action.data.currentProfile,
          originalImage: action.data.currentProfile?.profileImages?.find((image: any) => image.version === "original"
          )
        }
      }
    }
      ;
  }),

  // on(GeneralStateActions.addFeedPost, (state, action) => {
  //   const currentFeed = [...state.feedPosts];
  //   return {
  //     ...state,
  //     feedPosts: [...currentFeed, action.post]
  //   };
  // }),

  // on(GeneralStateActions.UpdateFeedData, (state, action) => {
  //   return {
  //     ...state,
  //     currentFeedPage: action.currentPage,
  //     totalFeedPosts: action.totalPosts,
  //     feedPosts: [...state.feedPosts, ...action.ids],
  //     loadingFeed: false,
  //     loadedFeed: true
  //   };
  // }),

  on(GeneralStateActions.upsertFeedLayout1, (state, action) => {
    return {
      ...state,
      loadingFeed: true,
      loadedFeed: false

    };
  }),
  on(GeneralStateActions.upsertFeedLayout1Sucsess, (state, action) => {
    return {
      ...state,
      layout1currentPage: action.currentPage,
      loadingFeed: false,
      loadedFeed: true,
      allLayoutArray: [...state.allLayoutArray, action.currentLayout]
      // layout1PrioritizedPost:action.layout1PrioritizedPost,
      // layout1hotDeals:action.layout1hotDeals,
      // layout1ToFollow:action.layout1ToFollow,
      // layout1PostFromTags:action.layout1PostFromTags,
      // layout1PostFromPrivateGroup:action.layout1PostFromPrivateGroup
    };
  }),
  on(GeneralStateActions.upsertFeedLayout2, (state, action) => {
    return {
      ...state,
      loadingFeed: true,
      loadedFeed: false

    };
  }),
  on(GeneralStateActions.upsertFeedLayout2Sucsess, (state, action) => {
    return {
      ...state,
      layout2currentPage: action.currentPage,
      loadingFeed: false,
      loadedFeed: true,
      allLayoutArray: [...state.allLayoutArray, action.currentLayout]
    };
  }),
  on(GeneralStateActions.addUserStore, (state, action) => {
    return {
      ...state,
      userStores: [...state.userStores, action.store]

    };
  }),
  on(GeneralStateActions.removeUserStore, (state, action) => {
    return {
      ...state,
      userStores: state.userStores.filter(s => s._id !== action.store._id)

    };
  }),
  on(GeneralStateActions.updateSpecificNotSetting, (state, action) => {
    return {
      ...state,
      [action.setting]: action.value
    };
  }), on(GeneralStateActions.updateUserPhone, (state, action) => {
    return {
      ...state,
      userAccount: {
        ...state.userAccount,
        phone: action.phone,
        isPhoneVerified: action.isPhoneVerified
      }
    };
  }),
  on(GeneralStateActions.loadNotificationSettings, (state, action) => {
    return {
      ...state,
      loadedNotSetting: false,
      loadingNotSetting: true
    };
  }),
  on(GeneralStateActions.loadNotificationSettingsSucsess, (state, action) => {
    return {
      ...state,
      loadedNotSetting: true,
      loadingNotSetting: false,
      newBusinessPostNot: action.newBusinessPostNot,
      newFriendsPostNot: action.newFriendsPostNot,
      newPostInGroupNot: action.newPostInGroupNot,
      newPostInTagNot: action.newPostInTagNot,
      followNot: action.followNot,
      joinGroupNot: action.joinGroupNot
    };
  }),

  // on(GeneralStateActions.upsertMainFeedPosts, (state, action) => {
  //   return {
  //     ...state,
  //     loadingFeed: true,
  //     loadedFeed: false
  //   };
  // }),
  on(GeneralStateActions.loadUserStores, (state, action) => {
    return {
      ...state,
      loadingUserStores: true,
      loadedUserStores: false
    };
  }), on(GeneralStateActions.loadUserStoresSucsess, (state, action) => {
    return {
      ...state,
      loadingUserStores: false,
      loadedUserStores: true,
      userStores: action.stores
    };
  }),

  on(GeneralStateActions.loadSearchNavSucsess, (state, action) => {
    return {
      ...state,
      searchNav: action.nav,
      loadedSearchNav: true,
      loadingSearchNav: false
    };
  }),

  on(GeneralStateActions.loadSearchNav, (state, action) => {
    return {
      ...state,
      loadedSearchNav: false,
      loadingSearchNav: true
    };
  }),

  on(GeneralStateActions.setGlobalSearch, (state, action) => {
    return {
      ...state,
      globalSearch: action.search
    };
  }),
  on(GeneralStateActions.getLastUpdatedDataSucsess, (state, action) => {
    return {
      ...state,
      lastUpdatedData: {
        businessUsers: action.businessUsers,
        regularUsers: action.regularUsers,
        tags: action.tags
      }
    };
  }),

  on(GeneralStateActions.getFlags, (state, action) => {
    let updateSate = {};
    if (action.flagOf === "post") {
      updateSate = {
        loadedPostFlags: false,
        loadingPostFlags: true
      };
    } else {
      updateSate = {
        loadedCommentFlags: false,
        loadingCommentFlags: true
      };
    }
    return {
      ...state,
      ...updateSate
    };
  }),

  on(GeneralStateActions.getFlagsSucsess, (state, action) => {
    let updateSate = {};
    if (action.flagOf === "post") {
      updateSate = {
        postFlags: action.flags,
        loadedPostFlags: true,
        loadingPostFlags: false
      };
    } else {
      updateSate = {
        commentFlags: action.flags,
        loadedCommentFlags: true,
        loadingCommentFlags: false
      };
    }
    return {
      ...state,
      ...updateSate
    };
  }),

  on(GeneralStateActions.UpdateUserProfileImages, (state, action) => {
    //const updatedUserProfiles = [...state.userProfile.userProfiles];
    let updatedCurrentProfile = { ...state.userAccount.currentProfile };
    updatedCurrentProfile.profileImages = action.images;
    updatedCurrentProfile.originalImage = action.images?.find((i: any) => i.version === "original");
    updatedCurrentProfile.showColorProfile = false;
    const newProfile = {
      ...state.userAccount,
      currentProfile: updatedCurrentProfile
    };
    return {
      ...state,
      userAccount: newProfile
    };
  }),

  on(GeneralStateActions.SetUserCurrentProfileData, (state, action) => {
    const newProfile = {
      ...state.userAccount,
      currentProfile: {
        ...action.data,
        originalImage: action.data?.profileImages?.find((image: any) => image.version === "original"
        )
      }
    };
    return {
      ...state,
      userAccount: newProfile
    };
  }),
  on(GeneralStateActions.loadUserSettingTabsSucsess, (state, action) => {
    const newProfile = {
      ...state.userAccount,
      currentProfile: {
        ...state.userAccount.currentProfile,
        settingTabs: action.tabs,
        loadingSettingTabs: false,
        loadedSettingTabs: true
      }
    };
    return {
      ...state,
      userAccount: newProfile
    };
  }), on(GeneralStateActions.loadUserSettingTabs, (state, action) => {
    const newProfile = {
      ...state.userAccount,
      currentProfile: {
        ...state.userAccount.currentProfile,
        loadingSettingTabs: true,
        loadedSettingTabs: false
      }
    };
    return {
      ...state,
      userAccount: newProfile
    };
  }),

  on(GeneralStateActions.updateUserProfileShowColor, (state, action) => {
    //const updatedUserProfiles = [...state.userProfile.userProfiles];
    let updatedCurrentProfile = { ...state.userAccount.currentProfile };
    updatedCurrentProfile.showColorProfile = true;
    const newProfile = {
      ...state.userAccount,
      currentProfile: updatedCurrentProfile
    };
    return {
      ...state,
      userAccount: newProfile
    };
  })
);

export const SelectGeneralFeature = (state: AppState) => state[generalStateFeatureKey];

export const SelectUserAccount = (state: AppState) => state[generalStateFeatureKey].userAccount;
export const SelectLastUpdated = (state: AppState) => state[generalStateFeatureKey].lastUpdatedData;
export const SelectSearchNav = (state: AppState) => {
  return {
    nav: state[generalStateFeatureKey].searchNav,
    search: state[generalStateFeatureKey].globalSearch,
    loaded: state[generalStateFeatureKey].loadedSearchNav,
    loading: state[generalStateFeatureKey].loadingSearchNav
  };
};
export const SelectSearchData = (state: AppState) => {
  return {
    searchTerm: state[generalStateFeatureKey].globalSearch,
    nav: state[generalStateFeatureKey].searchNav,
    loaded: state[generalStateFeatureKey].loadedSearchNav,
    loading: state[generalStateFeatureKey].loadingSearchNav
  };
};
export const SelectPostFlagData = (state: AppState) => {
  return {
    flags: state[generalStateFeatureKey].postFlags,
    loaded: state[generalStateFeatureKey].loadedPostFlags,
    loading: state[generalStateFeatureKey].loadingPostFlags
  };
};
export const SelectCommentFlagData = (state: AppState) => {
  return {
    flags: state[generalStateFeatureKey].commentFlags,
    loaded: state[generalStateFeatureKey].loadedCommentFlags,
    loading: state[generalStateFeatureKey].loadingCommentFlags
  };
};
export const SelectUserStoresData = (state: AppState) => {
  return {
    stores: state[generalStateFeatureKey].userStores,
    loaded: state[generalStateFeatureKey].loadedUserStores,
    loading: state[generalStateFeatureKey].loadingUserStores
  };
};

// export const SelectFeedData = (state: AppState) => {
//   return {
//     loaded: state[generalStateFeatureKey].loadedFeed,
//     loading: state[generalStateFeatureKey].loadingFeed,
//     ids: state[generalStateFeatureKey].feedPosts,
//     page: state[generalStateFeatureKey].currentFeedPage,
//     total: state[generalStateFeatureKey].totalFeedPosts
//   };
// };
export const SelectFeedDataLayout = (state: AppState) => {
  return {
    loaded: state[generalStateFeatureKey].loadedFeed,
    loading: state[generalStateFeatureKey].loadingFeed,
    layout1currentPage: state[generalStateFeatureKey].layout1currentPage,
    layout2currentPage: state[generalStateFeatureKey].layout2currentPage,
    allLayoutArray: state[generalStateFeatureKey].allLayoutArray
    // hotDeals: state[generalStateFeatureKey].layout1hotDeals,
    // layout1PrioritizedPost: state[generalStateFeatureKey].layout1PrioritizedPost,

    // layout1ToFollow: state[generalStateFeatureKey].layout1ToFollow,
    // layout1PostFromTags: state[generalStateFeatureKey].layout1PostFromTags,
    // layout1PostFromPrivateGroup: state[generalStateFeatureKey].layout1PostFromPrivateGroup,
  };
};
export const SelectNotificationSetting = (state: AppState) => {
  return {
    currentUser: state[generalStateFeatureKey].userAccount,
    loaded: state[generalStateFeatureKey].loadedNotSetting,
    loading: state[generalStateFeatureKey].loadingNotSetting,
    settings: [
      { key: "newBusinessPostNot", value: state[generalStateFeatureKey].newBusinessPostNot, data: {} },
      { key: "newFriendsPostNot", value: state[generalStateFeatureKey].newFriendsPostNot, data: {} },
      { key: "newPostInGroupNot", value: state[generalStateFeatureKey].newPostInGroupNot, data: {} },
      { key: "newPostInTagNot", value: state[generalStateFeatureKey].newPostInTagNot, data: {} },
      { key: "followNot", value: state[generalStateFeatureKey].followNot, data: {} },
      { key: "joinGroupNot", value: state[generalStateFeatureKey].joinGroupNot, data: {} }
    ]

  };
};
