import { createAction, createActionGroup, emptyProps, props } from "@ngrx/store";
import { Update } from "@ngrx/entity";
import { Tag } from "./tag.model";
import { UserProfile } from "../user-profile/user-profile.model";

export const upsertPostsByTag = createAction(
  "[Tag/API] Upsert Posts By Tag",
  props<{ slug: string, page: number, limit: number,postType:string }>()
);

export const upsertTagFollowers = createAction(
  "[UserProfile/API] Upsert Tag Followers",
  props<{ page: number, limit: number, slug: string }>()
);
export const loadTags = createAction(
  "[Tag/API] Load Tags",
  props<{ tags: Tag[] }>()
);

export const loadTagPostsData = createAction(
  "[Tag/API] Load Tag Posts Data",
  props<{ totalPosts:any,currentPostPage:number,tagSlug:string,postType:string }>()
);

export const loadTagFollowersData = createAction(
  "[Tag/API] Load Tag Followers Data",
  props<{ totalFollowers:number,currentFollowPage:number,tagSlug:string }>()
);

export const addTag = createAction(
  "[Tag/API] Add Tag",
  props<{ tag: Tag }>()
);

export const upsertTag = createAction(
  "[Tag/API] Upsert Tag",
  props<{ slug: string }>()
);

export const upsertTagSucsess = createAction(
  "[Tag/API] Upsert Tag Sucsess",
  props<{ tag: Tag }>()
);

export const upsertTagsSucsess = createAction(
  "[Tag/API] Upsert Tags Sucsess",
  props<{ tags: Tag[] }>()
);

export const addTags = createAction(
  "[Tag/API] Add Tags",
  props<{ tags: Tag[] }>()
);

export const upsertPopularTags = createAction(
  "[Tag/API] Upsert Popular Tags",
  props<{ globalSearch:string|null,page:number,limit:number }>()
);

export const upsertTagsSearchResult = createAction(
  "[Tag/API] Upsert Tags Search Result",
  props<{ globalSearch:string|null,page:number,limit:number }>()
);

export const upsertTagsSearchResultSucsess = createAction(
  "[Tag/API] Upsert Tags Search Result Sucsess",
  props<{ tags: Tag[],total:number,currentPage:number }>()
);

export const upsertPopularTagsSucsess = createAction(
  "[Tag/API] Upsert Popular Tags Sucsess",
  props<{ tags: Tag[],total:number,currentPage:number,ids:string[] }>()
);

export const loadTagValues = createAction(
  "[Tag/API] Load Tag Values",
  props<{ tagSlug: string, pageSlug: string }>()
);
export const loadTagValuesSuccess = createAction(
  "[Tag/API] Load Tag Values Success",
  props<{ values: any[], slug: string }>()
);

export const updateTag = createAction(
  "[Tag/API] Update Tag",
  props<{ id:string,changes:any }>()
);

export const updateTags = createAction(
  "[Tag/API] Update Tags",
  props<{ tags: Update<Tag>[] }>()
);

export const deleteTag = createAction(
  "[Tag/API] Delete Tag",
  props<{ id: string }>()
);

export const deleteTags = createAction(
  "[Tag/API] Delete Tags",
  props<{ ids: string[] }>()
);

export const clearTags = createAction(
  "[Tag/API] Clear Tags"
);
