import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { PostService } from "../../pages/post/post.service";
import { UpdatePostCommentData, upsertPost, upsertPostComments, upsertPostSucsess } from "../post/post.actions";
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import { upsertPostCommentsSuccess } from "./post-comment.actions";


@Injectable()
export class PostCommentEffects {
  upsertComments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(upsertPostComments),
      switchMap((action) =>
        this.postService.getPostComments(action.post, action.page, action.limit).pipe(
          mergeMap((res: any) => {
            // console.log(res)
            return [
              upsertPostCommentsSuccess({ postComments: res.comments }),
              UpdatePostCommentData({
                id: action.post, changes: {
                  totalComments: res.totalComments,
                  currentCommentPage: res.currentPage

                }
              })
            ];
          }),
          catchError(e => {
            return [];
          })
        )
      )
    );
  });


  constructor(private actions$: Actions, private postService: PostService) {
  }
}
