import { Injectable } from "@angular/core";
import Swal from "sweetalert2";

@Injectable({
  providedIn: "root"
})
export class ShareService {
  public  namePattern='^(?!.*[\u0590-\u05FF])[^\\s]+$';

  constructor() {
  }

  async share(data: { title: string, text: string, url: string }): Promise<void> {
    if (navigator.share) {
      try {
        await navigator.share(data);
        console.log("Data shared successfully");
      } catch (error) {
        console.error("Error sharing data:", error);
      }
    } else {
      console.error("Web Share API is not supported in your browser.");
    }
  }

  playToast(text: string, status: any) {
    Swal.fire({
      toast: true,
      position: "top-right",
      showConfirmButton: false,
      timer: 3000,
      text: text,
      icon: status
    });
  }
}
