import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment, URLS } from "../../../../../environments/environment";
import { map } from "rxjs/operators";


const API_USERS_URL = `${environment.apiUrl}/auth`;

@Injectable({
  providedIn: "root"
})
export class AuthHTTPService {
  constructor(private http: HttpClient) {
  }

  login(email: string, password: string, provider: string, idToken?: string) {
    return this.http.post(`${URLS.API}user-account/login`, {
      password:
      password, email: email, provider: provider, idToken: idToken
    });
  }

  register(email: string, password: string, provider: string, idToken?: string) {
    return this.http.post(`${URLS.API}user-account/register`, {
      password:
      password, email: email, provider: provider, idToken: idToken
    });
  }

  forgotPassword(email: string) {
    return this.http.post(`${URLS.API}user-account/forgot-password`, {
      email
    });
  }

  resetPassword(token: string, password: string) {
    return this.http.post(`${URLS.API}user-account/reset-password`, {
      password, token
    });
  }

  replaceProfile(profile: string) {
    return this.http.post(`${URLS.API}user-profile/replace-profile`, {
      profile
    });
  }

  validateUserName(userName: string, business: boolean) {
    //console.log(business)
    return this.http.get(`${URLS.API}auth/validate-user-name`, {
      params: {
        userName: userName, business
      }
    });
  }

  addProfileImage(blobUrl: string) {
    return this.http.post(`${URLS.API}user-profile/add-profile-image`, {
      blobUrl: blobUrl
    });
  }

  addBusinessProfileImage(blobUrl: string) {
    return this.http.post(`${URLS.API}user-profile/add-business-profile-image`, {
      blobUrl: blobUrl
    });
  }

  removeProfileImage() {
    return this.http.post(`${URLS.API}user-profile/remove-profile-image`, {});
  }

  getUserProfile() {
    return this.http.get(`${URLS.API}user-profile/get-user-profile`);
  }

  updateUserProfile(birthDate: string, description: string, userName: string,
                    fullName: string, business: boolean, businessUserName?: string) {
    return this.http.post(`${URLS.API}user-profile/update-profile`, {
      birthDate: birthDate, description: description,
      userName: userName, fullName: fullName, business: business, businessUserName
    });
  }

  updateBusinessUserProfile(description: string, userName: string,
                            fullName: string, webStoreLink: string) {
    return this.http.post(`${URLS.API}user-profile/update-business-profile`, {
      description, userName, fullName, webStoreLink
    });
  }

  addBusinessUserProfile(description: string, userName: string,
                         fullName: string, images: any, webStoreLink: string) {
    return this.http.post(`${URLS.API}user-profile/add-business-profile`, {
      description, userName, fullName, images, webStoreLink
    });
  }

  getCodeToVerifyPhone(phone: string, e164Phone: string) {
    return this.http.post(`${URLS.API}user-account/get-code-to-phone-verify`, {
      phone, e164Phone
    });
  }

  verifyPhoneCode(code: string) {
    return this.http.post(`${URLS.API}user-account/verify-phone-code`, {
      code
    }).pipe(map((res: any) => {
      return res.data;
    }));
  }

  deletePhone() {
    return this.http.post(`${URLS.API}user-account/delete-phone`, {}).pipe(map((res: any) => {
      return res.data;
    }));
  }

}
