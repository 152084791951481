import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { URLS } from "../../../../../../../environments/environment";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient) {
  }

  getNotifications(page: number,limit:number) {
    return this.http.get(`${URLS.API}notification/get-notifications`, {
      params: {
        page,limit
      }
    }).pipe(map((res: any) => {
      return res.data;
    }));
  }
}
