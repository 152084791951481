import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";

import { URLS } from "src/environments/environment";
import { catchError, map } from "rxjs/operators";
import { ActivatedRoute, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { isPlatformBrowser } from "@angular/common";

@Injectable()

export class JwtInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router,
              private route: ActivatedRoute,
              @Inject(PLATFORM_ID) private platformId: object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  isBrowser: boolean = false;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to the api url
    const user = this.authService.getUserValue;
    const isLoggedIn = user && user.accessToken;

    const isApiUrl = request.url.startsWith(URLS.API);
    if (isLoggedIn && isApiUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${user?.accessToken}`,
          "Content-Type": "application/json"
        }
      });
    }

    return next.handle(request).pipe(
      catchError(e => {
        return this.handleErrors(e);
        // return throwError(e);
      }),
      map(res => {
        return res;
      })
    );
  }

  handleErrors(e: any) {
    const url = this.router.url;
    // console.log('MY E',e)
    if (e.status === 500) {
      this.router.navigate(["/error/500"]);
    }
    switch (e.error.statusCode) {
      case 4001:
        //token expired
        this.router.navigate(["/auth/login"], { queryParams: { returnUrl: decodeURIComponent(url) } });
        if (this.isBrowser) {
          localStorage.clear();
        }
        //return []
        return throwError(e);
      case 4005:
        //deleted or not found
        this.router.navigate(["/error/404"]);
        return [];
      default:
        return throwError(e);
    }
  }
}
