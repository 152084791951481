import { NgModule } from "@angular/core";
import { Routes, RouterModule, ExtraOptions } from "@angular/router";
import { AuthGuard } from "./modules/auth/services/auth.guard";

export const routes: Routes = [
  // I change the order -auth use shared in layout
  //Error in the top
  {
    path: "error",
    loadChildren: () =>
      import("./modules/errors/errors.module").then((m) => m.ErrorsModule)
  },
  {
    path: "",
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import("./_metronic/layout/layout.module").then((m) => m.LayoutModule)
  },
  {
    path: "auth",
    loadChildren: () =>
      import("./modules/auth/auth.module").then((m) => m.AuthModule)
  },
  { path: "**", redirectTo: "error/404" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      scrollPositionRestoration: "enabled", // or 'top' to scroll to the top of the page
      anchorScrolling: "enabled"
    })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
