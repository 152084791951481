import { Inject, Injectable, Injector, OnDestroy, PLATFORM_ID } from "@angular/core";
import { Observable, BehaviorSubject, of, Subscription, throwError } from "rxjs";
import { map, catchError, switchMap, finalize } from "rxjs/operators";
import { UserModel } from "../models/user.model";
import { AuthModel } from "../models/auth.model";
import { environment, URLS } from "src/environments/environment";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { isPlatformBrowser } from "@angular/common";
import { AuthHTTPService } from "./auth-http/auth-http.service";
import { Store } from "@ngrx/store";
import { AppState } from "../../../reducers";
import { SetUserAccountData, SetUserCurrentProfileData } from "../../../state/general-state/general-state.actions";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { updateUserProfile } from "../../../state/user-profile/user-profile.actions";
import { ShareService } from "../../../_metronic/shared/share.service";

export type UserType = UserModel | null;

@Injectable({
  providedIn: "root"
})
export class AuthService {

  public userSubject: BehaviorSubject<UserType>;
  public user: Observable<UserType>;
  isBrowser: boolean;
  isLoading$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;
  registerTxt: string;
  loginTxt: string;
  registerOrLoginTxt: string;
  private translateService: TranslateService;

  constructor(
    private router: Router,
    private http: HttpClient,
    @Inject(PLATFORM_ID) platformId: Object,
    private authHttpService: AuthHTTPService,
    private store: Store<AppState>,
    private authHTTPService: AuthHTTPService,
    private injector: Injector,
    private shareService:ShareService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isBrowser = isPlatformBrowser(platformId);
    this.userSubject = new BehaviorSubject<UserType>(this.isBrowser ? JSON.parse(localStorage.getItem("currentUser")!) || "" : "");
    this.user = this.userSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
  }

  private getTranslateService(): TranslateService {
    if (!this.translateService) {
      this.translateService = this.injector.get(TranslateService);
    }
    return this.translateService;
  }

  public set setUserValue(user: UserType) {
    if (this.isBrowser)
      localStorage.setItem("currentUser", JSON.stringify(user));
    this.userSubject.next(user);
  }

  public get getUserValue(): UserType {
    return this.userSubject.value!;
  }

  // get currentUserValue(): UserType {
  //   return this.currentUserSubject.value;
  // }

  public get isLoggedIn(): boolean {
    return !!this.userSubject.value;
  }

  logout() {
    if (this.isBrowser)
      localStorage.removeItem("currentUser");
    this.userSubject.next(null as UserType);
    this.router.navigate(["/auth/login"]);
  }

  navigateTo(path: string) {
    const returnUrl = encodeURIComponent(this.router.url);
    this.router.navigate([`/auth/${path}`], { queryParams: { returnUrl } });
  }

  openLoginPopup(html: any) {
    const translateService = this.getTranslateService();
     this.registerOrLoginTxt = translateService.instant("LOGIN.register_or_login");
    this.loginTxt =translateService.instant("REGISTER.login");
    this.registerTxt =    translateService.instant("LOGIN.register");
    const returnUrl = encodeURIComponent(this.router.url);
    Swal.fire({
      html: html +
        `<p>${this.registerOrLoginTxt}</p>
 <a class="link-primary cursor-pointer" id="login-button">${this.loginTxt}</a> /
 <a class="link-primary cursor-pointer" id="register-button">${this.registerTxt}</a>`,
      showConfirmButton: false,
      didOpen: () => {
        const login = document.getElementById('login-button');
        if (login) {
          login.addEventListener('click', () => {
            this.navigateTo('login');
            Swal.close();
          });
        }

        const register = document.getElementById('register-button');
        if (register) {
          register.addEventListener('click', () => {
            this.navigateTo('registration');
            Swal.close();
          });
        }
      }
    });

 //    Swal.fire({
 //      html: html +
 //        `<p >this.registerOrLoginTxt}</p>` +
 //        `  <a href="auth/login" [queryParams]="{ returnUrl: ${returnUrl} }">${this.loginTxt}</a> /
 // <a href="auth/registration" [queryParams]="{ returnUrl: ${returnUrl} }">${this.registerTxt}</a>`,
 //      showConfirmButton: false
 //    });
  }

  setUserInSateAndLocalStorage(user:any)
  {
    this.setUserValue = user;
    this.authHTTPService.getUserProfile().subscribe((res: any) => {
      this.store.dispatch(SetUserAccountData({
        data: { ...res.data }
      }));
    });
  }

  login(email: string, password: string, provider: string, idToken?: string) {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(email, password, provider, idToken).pipe(
      map((auth: any) => {
        this.setUserInSateAndLocalStorage(auth.data);
        //this.store.dispatch(SetUserAccountData({ data: auth.data }));
        return auth;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  replaceProfile(profile: string) {
    return this.authHttpService.replaceProfile(profile).pipe(
      map((auth: any) => {
       // console.log(auth)
        this.setUserInSateAndLocalStorage(auth.data);
        //this.store.dispatch(SetUserAccountData({ data: auth.data }));
        return auth;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  register(email: string, password: string, provider: string, idToken?: string) {
    this.isLoadingSubject.next(true);
    return this.authHttpService.register(email, password, provider, idToken).pipe(
      map((auth: any) => {
        this.setUserValue = auth.data;
        this.authHTTPService.getUserProfile().subscribe((res: any) => {
          this.store.dispatch(SetUserAccountData({
            data: { ...res.data }
          }));
        });
        return auth;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  updateUserProfile(birthDate: string,
                    description: string,
                    userName: string,
                    fullName: string,
                    business:boolean,
                    businessUserName?:string,
  ) {
    return this.authHttpService.updateUserProfile(birthDate, description, userName,
      fullName,business,businessUserName).pipe(
      map((res: any) => {
        const translateService = this.getTranslateService();
        this.shareService.playToast(translateService.instant('ON-BOARDING.'+res.msg),'success')
        return res;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
  updateBusinessUserProfile(
                    description: string,
                    userName: string,
                    fullName: string,
                    webStoreLink:string
  ) {
    this.isLoadingSubject.next(true);
    return this.authHttpService.updateBusinessUserProfile(description, userName,
      fullName,webStoreLink).pipe(
      map((res: any) => {
        if(res.statusCode!=500)
        {
          const translateService = this.getTranslateService();
          this.shareService.playToast(translateService.instant('BUSINESS-PROFILE.'+res.msg),'success')
          this.store.dispatch(SetUserCurrentProfileData({data:res.data}))
          this.store.dispatch(updateUserProfile({id:res.data._id,changes:res.data}))
        }
        return res;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  addBusinessUserProfile(
                    description: string,
                    userName: string,
                    fullName: string,
                    images:any,
                    webStoreLink:string
  ) {
    this.isLoadingSubject.next(true);
    return this.authHttpService.addBusinessUserProfile(description, userName,
      fullName,images,webStoreLink).pipe(
      map((res: any) => {
        if(res.statusCode!=500) {
          const translateService = this.getTranslateService();
          this.shareService.playToast(translateService.instant('BUSINESS-PROFILE.' + res.msg), 'success')
          //console.log(res.data)
          this.store.dispatch(SetUserAccountData({
            data: { ...res.data }
          }));
          this.store.dispatch(updateUserProfile({ id: res.data._id, changes: res.data }))
        }
        return res;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(email: string) {
    this.isLoadingSubject.next(true);
    return this.authHttpService.forgotPassword(email).pipe(
      map((auth: any) => {
        this.setUserValue = auth.data;
        // this.authHTTPService.getUserProfile().subscribe((res: any) => {
          // this.store.dispatch(SetUserAccountData({
          //   data: { ...res.data }
          // }));
        // });
        return auth;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  resetPassword(token:string,password: string) {
    this.isLoadingSubject.next(true);
    return this.authHttpService.resetPassword(token,password).pipe(
      map((auth: any) => {
        this.setUserValue = auth.data;
        // this.authHTTPService.getUserProfile().subscribe((res: any) => {
          // this.store.dispatch(SetUserAccountData({
          //   data: { ...res.data }
          // }));
        // });
        return auth;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

}
