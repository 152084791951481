import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  upsertPopularTags,
  upsertPopularTagsSucsess,
  upsertTag, upsertTagsSearchResult, upsertTagsSearchResultSucsess,
  upsertTagsSucsess,
  upsertTagSucsess
} from "./tag.actions";
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import { TagService } from "../../pages/tag/tag.service";
import { loadUserPostsData, loadUserTagsData, upsertTagsByUser } from "../user-profile/user-profile.actions";
import { UserProfileService } from "../../pages/salez-profile/user-profile.service";
import { upsertSearchResultPosts } from "../post/post.actions";
import { Router } from "@angular/router";

@Injectable()
export class TagEffects {

 upsertTag$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(upsertTag),
      switchMap((action) =>
        this.tagService.getTagData(action.slug).pipe(
          map((res: any) => {
            console.log(res)
            return upsertTagSucsess({tag:res})
          }),
          catchError(e => {
           // console.log(e)
            if(e.error.deletedError)
            {
              // console.log('hi')
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate([`/tag/${e.error.data.newSlug}/over-view/all`])});
            }

            return [];
          })
        )
      )
    );
  });

 upsertPopularTags$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(upsertPopularTags),
      switchMap((action) =>
        this.tagService.getTags(action.globalSearch,action.limit,action.page).pipe(
          map((res: any) => {
            //console.log(res)
            return upsertPopularTagsSucsess({tags:res.tags,total:res.total,
              currentPage:res.currentPage,ids:res.ids})
          }),
          catchError(e => {
            return [];
          })
        )
      )
    );
  });

 upsertTagsSearchResult$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(upsertTagsSearchResult),
      switchMap((action) =>
        this.tagService.getTags(action.globalSearch,action.limit,action.page).pipe(
          map((res: any) => {
            //console.log(res)
            return upsertTagsSearchResultSucsess({tags:res.tags,total:res.total,
              currentPage:res.currentPage})
          }),
          catchError(e => {
            return [];
          })
        )
      )
    );
  });

 upsertUserTags$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(upsertTagsByUser),
      switchMap((action) =>
        this.userProfileService.getTagByUser(action.userName,action.page,action.limit).pipe(
          mergeMap((res: any) => {
            // console.log(res)
            return [
              upsertTagsSucsess({tags:res.tags}),
              loadUserTagsData({
                totalTags: res.totalTags,
                currentTagPage: res.currentPage, id: res._id
              }),]
          }),
          catchError(e => {
            return [];
          })
        )
      )
    );
  });

  constructor(private actions$: Actions,   private tagService: TagService,
              private userProfileService:UserProfileService,private router: Router) {
    // console.log(this.actions$);
  }
}
