import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import { loadTagFollowersData, loadTagPostsData, upsertTagFollowers, upsertTagsSucsess } from "../tag/tag.actions";
import {
  loadUserFollowersData, loadUserGroupRequestData,
  loadUserTagsData,
  upsertBusinessUserProfiles,
  upsertBusinessUserProfilesSuccess, upsertFollowersByUser,
  upsertPublicUserProfile, upsertPublicUserProfileSucsess,
  upsertRegularUserProfiles,
  upsertRegularUserProfilesSuccess, upsertSearchResultUserProfiles, upsertSearchResultUserProfilesSucsess,
  upsertTagFollowersSucsess, upsertUserGroupRequest, upsertUserProfilesSucsess
} from "./user-profile.actions";
import { TagService } from "../../pages/tag/tag.service";
import { UserProfile } from "./user-profile.model";
import { UserProfileService } from "../../pages/salez-profile/user-profile.service";
import { loadGroupMembersData, upsertGroupMembers } from "../group/group.actions";
import { GroupService } from "../../pages/group/group.service";
import { upsertGroupRequestsSuccess } from "../group-request/group-request.actions";
import { loadUserSettingTabs, loadUserSettingTabsSucsess } from "../general-state/general-state.actions";


@Injectable()
export class UserProfileEffects {

  loadTagFollowers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(upsertTagFollowers),
      switchMap((action) =>
        this.tagService.getTagFollowers(action.slug, action.page, action.limit).pipe(
          mergeMap((res: any) => {
            //console.log(res)
            return [upsertTagFollowersSucsess({ userProfiles: res.users }),
              loadTagFollowersData({
                totalFollowers: res.totalUsers,
                currentFollowPage: res.currentPage,
                tagSlug: action.slug
              })];
          }),
          catchError(e => {
            return [];
          })
        )
      )
    );
  });

  loadGroupMembers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(upsertGroupMembers),
      switchMap((action) =>
        this.groupService.getGroupMembers(action.name, action.page, action.limit).pipe(
          mergeMap((res: any) => {
           // console.log(res.users)
            return [upsertUserProfilesSucsess({ userProfiles: res.users }),
              loadGroupMembersData({
                totalMembers: res.totalMembers,
                currentMemberPage: res.currentPage,
                name: action.name
              })];
          }),
          catchError(e => {
            return [];
          })
        )
      )
    );
  });

  upsertBisnussUsersProfiles = createEffect(() => {
    return this.actions$.pipe(
      ofType(upsertBusinessUserProfiles),
      switchMap((action) =>
        this.userProfileService.getUserProfiles(action.page, action.limit,
          action.globalSearch,
          action.selectType).pipe(
          map((res: any) => {
            //console.log(res)
            return upsertBusinessUserProfilesSuccess({
              userProfiles: res.users,
              total: res.totalUsers.length,
              currentPage: res.currentPage,
              usersIds:res.totalUsers.map((u:any)=>u._id)
            });
          }),
          catchError(e => {
            return [];
          })
        )
      )
    );
  });

  loadUserStettingTabs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadUserSettingTabs),
      switchMap((action) =>
        this.userProfileService.getProfileSettingTabs().pipe(
          map((res: any) => {
            //console.log(res)
            return loadUserSettingTabsSucsess({
              tabs:res
            });
          }),
          catchError(e => {
            return [];
          })
        )
      )
    );
  });

  upsertRegularUsersProfiles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(upsertRegularUserProfiles),
      switchMap((action) =>
        this.userProfileService.getUserProfiles(action.page, action.limit,
          action.globalSearch,
          action.selectType).pipe(
          map((res: any) => {
            //console.log(res)
            return upsertRegularUserProfilesSuccess({
              userProfiles: res.users, total: res.totalUsers,
              currentPage: res.currentPage,usersIds:res.users.map((u:any)=>u._id)
            });
          }),
          catchError(e => {
            return [];
          })
        )
      )
    );
  });

  upsertUsersProfiles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(upsertSearchResultUserProfiles),
      switchMap((action) =>
        this.userProfileService.getUserProfiles(action.page, action.limit,
          action.globalSearch,
          action.selectType).pipe(
          map((res: any) => {
            //console.log(res)
            return upsertSearchResultUserProfilesSucsess({
              userProfiles: res.users,
              currentPage:res.currentPage,
              total:res.totalUsers
            });
          }),
          catchError(e => {
            return [];
          })
        )
      )
    );
  });

  upsertPublicUserProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(upsertPublicUserProfile),
      switchMap((action) =>
        this.userProfileService.getPublicUserProfile(action.userProfileName).pipe(
          map((res: any) => {
            //console.log(res)
            return upsertPublicUserProfileSucsess({
              userProfile:res
            });
          }),
          catchError(e => {
            return [];
          })
        )
      )
    );
  });

  loadUserFollowers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(upsertFollowersByUser),
      switchMap((action) =>
        this.userProfileService.getFollowersByUser(action.userName,action.page,
          action.limit,action.followType).pipe(
          mergeMap((res: any) => {
            // console.log(res)
            return [
              upsertUserProfilesSucsess({userProfiles:res.users}),
              loadUserFollowersData({
                totalFollowers: res.totalUsers,
                currentFollowPage: res.currentPage, id: res._id,
                followType:action.followType
              }),]
          }),
          catchError(e => {
            return [];
          })
        )
      )
    );
  });

  loadUserGroupRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(upsertUserGroupRequest),
      switchMap((action) =>
        this.userProfileService.getUserGroupRequest(action.page,
          action.limit).pipe(
          mergeMap((res: any) => {
            // console.log(res)
            return [
              upsertGroupRequestsSuccess({groupRequests:res.requests}),
              loadUserGroupRequestData({
                id:action.id,
                totalRequests: res.totalRequests.length,
                currentGroupRequestPage: res.currentPage,
                requests:res.totalRequests.map((r:any)=>r._id)
              }),]
          }),
          catchError(e => {
            return [];
          })
        )
      )
    );
  });

  constructor(private actions$: Actions, private userProfileService: UserProfileService,
              private tagService: TagService,
              private groupService:GroupService) {
  }
}
