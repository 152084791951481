import { createReducer, createSelector, on } from "@ngrx/store";
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Notification } from './notification.model';
import * as NotificationActions from "./notification.actions";
import { AppState } from "../../reducers";
import { GroupRequest } from "../group-request/group-request.model";
import * as GeneralStateActions from "../general-state/general-state.actions";

export const notificationsFeatureKey = 'notifications';

export interface State extends EntityState<Notification> {
  // additional entities state properties
  notifications:string[],
  currentPage:number,
  totalNotifications:number,
  loaded:boolean,
  errorLoad:boolean
}

export const adapter: EntityAdapter<Notification> = createEntityAdapter<Notification>({
  selectId: (n: Notification) => n._id
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  notifications:[],
  currentPage:0,
  totalNotifications:0,
  loaded:false,
  errorLoad:false
});

export const reducer = createReducer(
  initialState,
  on(NotificationActions.addNotification,
    (state, action) => adapter.addOne(action.notification, state)
  ),
  on(NotificationActions.upsertNotification,
    (state, action) => adapter.upsertOne(action.notification, state)
  ),
  on(NotificationActions.addNotifications,
    (state, action) => adapter.addMany(action.notifications, state)
  ),
  on(NotificationActions.upsertNotificationsSucsess,
    (state, action) => adapter.upsertMany(action.notifications, {
      ...state,
      currentPage:action.currentPage,
      totalNotifications:action.totalNotifications,
      notifications:action.allNotifications,
      loaded:true,
      errorLoad:false
    })
  ),
  on(NotificationActions.upsertNotificationsFailed, (state, action) => {
    return {
      ...state,
      errorLoad:true
    };
  }),
  on(NotificationActions.updateNotification,
    (state, action) => adapter.updateOne(action.notification, state)
  ),
  on(NotificationActions.updateNotifications,
    (state, action) => adapter.updateMany(action.notifications, state)
  ),
  on(NotificationActions.deleteNotification,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(NotificationActions.deleteNotifications,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(NotificationActions.loadNotifications,
    (state, action) => adapter.setAll(action.notifications, state)
  ),
  on(NotificationActions.clearNotifications,
    state => adapter.removeAll(state)
  ),
);

export const SelectNotificationFeature = (state: AppState) => state[notificationsFeatureKey];
export const SelectNotificationsByIds = (ids: string[]) => createSelector(
  selectAll,
  (entities: Notification[]) => entities.filter(u => ids?.includes(u._id))   .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
);

export const SelectNotificationData = (state: AppState) => {
  return {
    totalNotifications: state[notificationsFeatureKey].totalNotifications,
    currentPage: state[notificationsFeatureKey].currentPage,
    ids: state[notificationsFeatureKey].notifications,
    loaded:state[notificationsFeatureKey].loaded,
    errorLoad:state[notificationsFeatureKey].errorLoad,

  };
};
export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors(SelectNotificationFeature);
