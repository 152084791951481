import { createFeature, createReducer, createSelector, on } from "@ngrx/store";
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { PostComment } from './post-comment.model';
import * as PostCommentActions from "./post-comment.actions";

import { Post } from "../post/post.model";
import { AppState } from "../../reducers";

export const postCommentsFeatureKey = 'postComments';

export interface State extends EntityState<PostComment> {
  // additional entities state properties
}

export const adapter: EntityAdapter<PostComment> = createEntityAdapter<PostComment>({
  selectId: (pc: PostComment) => pc._id
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export const reducer = createReducer(
  initialState,
  on(PostCommentActions.addPostComment,
    (state, action) => adapter.addOne(action.postComment, state)
  ),
  on(PostCommentActions.upsertPostComment,
    (state, action) => adapter.upsertOne(action.postComment, state)
  ),
  on(PostCommentActions.addPostComments,
    (state, action) => adapter.addMany(action.postComments, state)
  ),
  // on(PostCommentActions.upsertPostComments,
  //   (state, action) => adapter.upsertMany(action.postComments, state)
  // ),
  //
  on(PostCommentActions.upsertPostCommentsSuccess,
    (state, action) => adapter.upsertMany(action.postComments, state)
  ),
  on(PostCommentActions.updatePostComment,
    (state, action) =>{
    console.log(action)
    return adapter.updateOne({
      id: action.id,
      changes: {...action.changes }
    }, state)
  }
  ),
  on(PostCommentActions.updatePostComments,
    (state, action) => adapter.updateMany(action.postComments, state)
  ),
  on(PostCommentActions.deletePostComment,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(PostCommentActions.deletePostComments,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(PostCommentActions.loadPostComments,
    (state, action) => adapter.setAll(action.postComments, state)
  ),
  on(PostCommentActions.clearPostComments,
    state => adapter.removeAll(state)
  ),
);

export const SelectPostCommentFeature = (state: AppState) => state[postCommentsFeatureKey];

export const SelectPostCommentsIds = (ids: string[],short:boolean) => createSelector(
  selectAll,
  (entities: PostComment[]) => {
 const all= entities.filter(g => ids?.includes(g._id)).sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
    return short?all.slice(0, short ? 3 : 0):all;
  }
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors(SelectPostCommentFeature);
