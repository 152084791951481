import { createAction, createActionGroup, emptyProps, props } from "@ngrx/store";
import { layout1, layout2 } from "./general-state.reducer";


export const SetUserAccountData = createAction(
  '[GeneralState] Set User Account Data',
  props<{ data:any }>()
);

export const SetUserCurrentProfileData = createAction(
  '[GeneralState] Set User Current Profile Data',
  props<{ data:any }>()
);

export const addFeedPost = createAction(
  '[GeneralState] Add feed Post',
  props<{ post:string }>()
);

export const UpdateUserProfileImages = createAction(
  '[GeneralState] Update User Profile Images',
  props<{images:any }>()
);
export const updateUserProfileShowColor = createAction(
  '[GeneralState] Update User Profile Show Color',
  props<{ profileId:any }>()
);

// export const upsertMainFeedPosts = createAction(
//   "[GeneralState] Upsert Main Feed Posts",
//   props<{ page:number,limit:number }>()
// );
//
// export const UpdateFeedData = createAction(
//   '[GeneralState] Update Feed Data',
//   props<{ ids:any,currentPage:number,totalPosts:number}>()
// );

export const upsertFeedLayout1 = createAction(
  "[GeneralState] Upsert Feed Layout 1",
  props<{ page:number }>()
);

export const upsertFeedLayout1Sucsess = createAction(
  "[GeneralState] Upsert Feed Layout 1 Sucsess",
  props<{
    currentPage:number,
    currentLayout:layout1;
    // layout1PrioritizedPost:string,
    // layout1hotDeals: string[],
    // layout1ToFollow:string[] ,
    // layout1PostFromTags:string ,
    // layout1PostFromPrivateGroup:string ,
  }>()
);
export const upsertFeedLayout2 = createAction(
  "[GeneralState] Upsert Feed Layout 2",
  props<{ page:number }>()
);

export const upsertFeedLayout2Sucsess = createAction(
  "[GeneralState] Upsert Feed Layout 2 Sucsess",
  props<{
    currentPage:number,
    currentLayout:layout2;
  }>()
);

export const addUserStore = createAction(
  '[GeneralState] add User Store',
  props<{ store:any}>()
);
export const removeUserStore = createAction(
  '[GeneralState] Remove User Store',
  props<{ store:any}>()
);

export const updateSpecificNotSetting = createAction(
  '[GeneralState] Update Specific Not Setting',
  props<{ setting:string,value:string[]}>()
);
export const loadNotificationSettings = createAction(
  '[GeneralState] load Notification Settings',
);
export const updateUserPhone = createAction(
  '[GeneralState] Update User Phone',
  props<{ phone:string,isPhoneVerified:boolean}>()
);
export const loadNotificationSettingsSucsess = createAction(
  '[GeneralState] load Notification Settings Sucsess',
  props<{
    newBusinessPostNot:string[],
    newFriendsPostNot:string[],
    newPostInGroupNot:string[],
    newPostInTagNot:string[],
    followNot:string[],
    joinGroupNot:string[],
  }>()
);

export const loadSearchNav = createAction(
  '[GeneralState] load Search Nav',
  props<{ search:string}>()
);
export const loadSearchNavSucsess = createAction(
  '[GeneralState] load Search Nav Sucsess',
  props<{ nav:any}>()
);
export const loadUserStores = createAction(
  '[GeneralState] load User Stores',
);
export const loadUserStoresSucsess = createAction(
  '[GeneralState] oad User Stores Sucsess',
  props<{ stores:any[]}>()
);
export const loadUserSettingTabs = createAction(
  '[GeneralState] load User Setting Tabs',
);

export const loadUserSettingTabsSucsess = createAction(
  '[GeneralState] load User Setting Tabs Sucsess',
  props<{ tabs:any }>()
);

export const setGlobalSearch = createAction(
  '[GeneralState] Set Global Search',
  props<{ search:any }>()
);

export const getLastUpdatedData = createAction(
  '[GeneralState] Get Last Updated Data',
);
export const getLastUpdatedDataSucsess = createAction(
  '[GeneralState] Get Last Updated Data Sucsess',
  props<{ businessUsers:string[],regularUsers:string[],tags:string[] }>()
);

export const getFlags = createAction(
  '[GeneralState] Get Flags',
  props<{ flagOf:string }>()
);

export const getFlagsSucsess = createAction(
  '[GeneralState] Get Flags Sucsess',
  props<{ flags:string,flagOf:string }>()
);
